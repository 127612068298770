import { FC } from 'react';
import { Box, useMantineTheme } from '@mantine/core';
import { Term } from 'types';
import { TableView } from '../TableView/TableView';
import { Container } from '@mantine/core';
import { CardsView } from '../CardsView';
import { useMediaQuery } from '@mantine/hooks';

interface ICreatedTerms {
  terms: Term[];
  loading: boolean;
  onDeleteTerm(termId: string): void;
}

export const CreatedTerms: FC<ICreatedTerms> = ({ terms, loading, onDeleteTerm }) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);
  return (
    <Box>
      {!isMobile ? (
        <Container fluid p="sm" mx={0} style={{ backgroundColor: '#fff' }}>
          <TableView loading={loading} termsBySupplier={terms} onDelete={onDeleteTerm} />
        </Container>
      ) : (
        <Container fluid p="lg" mx={0}>
          <CardsView loading={loading} termsBySupplier={terms} onDelete={onDeleteTerm} />
        </Container>
      )}
    </Box>
  );
};
