import { FC, useState } from 'react';
import { Combobox, Group, Text, Image, InputBase, Input, useCombobox } from '@mantine/core';
import classes from './WidgetComponent.module.scss';

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  image: string;
  label: string;
  description: string;
}

interface IWidgetComponentProps {
  setChosenWidgetLook(value: string): void;
  setOpen(value: boolean): void;
}

export const WidgetComponent: FC<IWidgetComponentProps> = ({ setChosenWidgetLook, setOpen }) => {
  const buttonLinks = {
    button1:
      'https://marketplace-widget-assets.s3.eu-west-1.amazonaws.com/White_with_coloured_pebbles.png',
    button2:
      'https://marketplace-widget-assets.s3.eu-west-1.amazonaws.com/Navy_with_coloured_pebbles.png',
    button3: 'https://marketplace-widget-assets.s3.eu-west-1.amazonaws.com/All_white.png',
    button4: 'https://marketplace-widget-assets.s3.eu-west-1.amazonaws.com/All_navy.png',
    button5: 'https://marketplace-widget-assets.s3.eu-west-1.amazonaws.com/White_pill_button.png',
    button6:
      'https://marketplace-widget-assets.s3.eu-west-1.amazonaws.com/Light_blue_pill_button.png',
    button7:
      'https://marketplace-widget-assets.s3.eu-west-1.amazonaws.com/Dark_blue_pill_button.png',
    button8:
      'https://marketplace-widget-assets.s3.eu-west-1.amazonaws.com/Green_lightening_button.png',
  };

  const data = [
    {
      image: buttonLinks.button1,
      label: 'Button 1',
      value: buttonLinks.button1,
      description: 'White with coloured pebbles',
    },
    {
      image: buttonLinks.button2,
      label: 'Button 2',
      value: buttonLinks.button2,
      description: 'Navy with coloured pebbles',
    },
    {
      image: buttonLinks.button3,
      label: 'Button 3',
      value: buttonLinks.button3,
      description: 'All white',
    },
    {
      image: buttonLinks.button4,
      label: 'Button 4',
      value: buttonLinks.button4,
      description: 'All navy',
    },
    {
      image: buttonLinks.button5,
      label: 'Button 5',
      value: buttonLinks.button5,
      description: 'White pill button',
    },
    {
      image: buttonLinks.button6,
      label: 'Button 6',
      value: buttonLinks.button6,
      description: 'Light blue pill button',
    },
    {
      image: buttonLinks.button7,
      label: 'Button 7',
      value: buttonLinks.button7,
      description: 'Dark blue pill button',
    },
    {
      image: buttonLinks.button8,
      label: 'Button 8',
      value: buttonLinks.button8,
      description: 'Green lightening button',
    },
  ];

  const [value, setValue] = useState<string | null>(null);
  const selectedOption = data.find((item) => item.value === value);
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const SelectItem = ({ image, label, description }: ItemProps) => {
    return (
      <Group wrap={'nowrap'}>
        <Image radius="md" src={image} maw={230} />
        <div>
          <Text size="md">{label}</Text>
          <Text size="xs">{description}</Text>
        </div>
      </Group>
    );
  };

  const options = data.map((item, key) => (
    <Combobox.Option
      value={item.value}
      key={key}
      className={classes.item}
      active={value === item.value}
    >
      <SelectItem image={item.image} label={item.label} description={item.description} />
    </Combobox.Option>
  ));

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={(val) => {
        setChosenWidgetLook(val);
        setOpen(true);
        setValue(val);
        combobox.closeDropdown();
      }}
    >
      <Combobox.Target>
        <InputBase
          component="button"
          size="md"
          pointer
          rightSection={<Combobox.Chevron />}
          rightSectionPointerEvents="none"
          classNames={{ label: classes.label }}
          className={classes.input}
          label="Choose widget style"
          onClick={() => combobox.toggleDropdown()}
        >
          {selectedOption?.label || <Input.Placeholder>Pick one</Input.Placeholder>}
        </InputBase>
      </Combobox.Target>

      <Combobox.Dropdown mah={375} style={{ overflowY: 'auto' }}>
        <Combobox.Options>{options}</Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
