import { gql } from '@apollo/client';

const SupplierMemberships = gql`
  query SupplierMemberships($supplierId: UUID!, $offset: Int, $limit: Int) {
    paginatedMarketplaceSupplierMemberships(
      supplierId: $supplierId
      offset: $offset
      limit: $limit
    ) {
      memberships {
        id
        isSuperadmin
        adminAccess {
          id
          name
          niceName
        }
        supplierAdmin {
          id
          emailAddress
          fullName
          cognitoStatus
        }
      }
      total
    }
  }
`;

export default SupplierMemberships;
