import {
  Box,
  Title,
  Text,
  Table,
  Skeleton,
  Center,
  useMantineTheme,
  ActionIcon,
  Flex,
  Button,
} from '@mantine/core';
import classes from './CustomerSummary.module.scss';
import { useQuery } from '@apollo/client';
import { showErrorMessage } from 'utils/showErrorMessage/showErrorMessage';
import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { PebbleTextInput } from 'components/common';
import SortSettings from './SortSettings/SortSettings';
import { sortCustomers } from './SortSettings/SortSettings.utils';
import { SupplierCustomersFromBookings } from 'graphql/queries/supplier-customers-from-bookings';
import { useMediaQuery } from '@mantine/hooks';
import { Actions, trackAction } from 'utils/amplitude';
import { Trash, CheckCircle, Person, CaretUp, CaretDown, X } from '@phosphor-icons/react';

interface ICustomerSummaryProps {
  supplierId: string;
  token: string;
  selectedActivitiesId: string[];
  setCustomerEmails(val: string[]): void;
}

type CustomerInfo = {
  customerId: string;
  customerFullName: string;
  customerEmail: string;
  children: [
    {
      fullName: string;
      id: string;
    },
  ];
};

export type CustomersTableContent = {
  customerId: string;
  customerFullName: string;
  childrenNames: string[];
};

export enum SortingRule {
  A_TO_Z = 'a_to_z',
  Z_TO_A = 'z_to_a',
}

const CustomerSummary: React.FC<ICustomerSummaryProps> = ({
  supplierId,
  token,
  selectedActivitiesId,
  setCustomerEmails,
}: ICustomerSummaryProps) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const [excludedCustomersId, setExcludedCustomersId] = useState<string[]>([]);
  const [childInfoShowed, setChildInfoShowed] = useState<boolean>(false);
  const [showedGuardianId, setShowedGuardianId] = useState<string | null>(null);
  const [showSort, setShowSort] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState('');
  const [sortingRule, setSortingRule] = useState<SortingRule>(SortingRule.A_TO_Z);

  const { loading, data } = useQuery(SupplierCustomersFromBookings, {
    skip: !supplierId,
    context: {
      headers: {
        Authorization: `${token}`,
      },
    },
    variables: {
      supplierId,
      activityIds: selectedActivitiesId,
    },
    fetchPolicy: 'cache-and-network',
    onError: (error) => {
      showErrorMessage(error);
    },
  });

  const customersInfo: CustomerInfo[] = data?.supplierCustomersFromBookings;

  const filteredTableData: CustomersTableContent[] = useMemo(() => {
    const tableInfo = customersInfo?.map((d: CustomerInfo) => {
      return {
        customerId: d.customerId,
        customerFullName: d.customerFullName,
        childrenNames: d.children?.map((childInfo) => childInfo.fullName),
      };
    });

    const filteredOptions =
      searchInput !== ''
        ? tableInfo.filter((item) => {
            return (
              item.customerFullName.toLowerCase().includes(searchInput.toLowerCase().trim()) ||
              item.childrenNames.some((childName) =>
                childName.toLowerCase().includes(searchInput.toLowerCase().trim()),
              )
            );
          })
        : tableInfo;

    return filteredOptions;
  }, [searchInput, customersInfo]);

  const filteredCustomerEmails = useMemo<string[]>(() => {
    const filteredCustomers: CustomerInfo[] = customersInfo?.filter((customerInfo) => {
      return !excludedCustomersId.includes(customerInfo.customerId);
    });
    return filteredCustomers?.map((customer) => customer.customerEmail);
  }, [customersInfo, excludedCustomersId]);

  useEffect(() => {
    setCustomerEmails(filteredCustomerEmails);
  }, [filteredCustomerEmails, setCustomerEmails]);

  return (
    <>
      <Flex align="center" gap="md" mb="sm">
        <Flex
          align="center"
          onClick={() => {
            setShowSort(!showSort);
            trackAction(Actions.ENROL_SORT);
          }}
        >
          <Text mr="4px" className={classes.sortHeader}>
            Sort by
          </Text>
          {showSort ? (
            <CaretUp size={20} weight="bold" color={theme.colors.blue[6]} />
          ) : (
            <CaretDown size={20} weight="bold" color={theme.colors.blue[6]} />
          )}
        </Flex>
        <PebbleTextInput
          placeholder="Filter by name"
          value={searchInput}
          onChange={(e) => setSearchInput(e.currentTarget.value)}
          rightSection={
            searchInput !== '' && (
              <ActionIcon
                title="cross"
                size={24}
                ml={4}
                variant="subtle"
                onClick={() => {
                  setSearchInput('');
                }}
              >
                <X weight="bold" color={theme.colors.gray[5]} />
              </ActionIcon>
            )
          }
          onBlur={() => trackAction(Actions.ENROL_CUSTOMER_SEARCH)}
        />
      </Flex>

      <Box className={classes.cardWrapper}>
        <Title ta={'start'} size={'md'} lh="18px" mb={8}>
          Customer summary
        </Title>
        <Table className={classes.table} title="Customer summary">
          <thead>
            <tr>
              <th>Name</th>
              <th>Marketing</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="tdNoResult" colSpan={7}>
                  <Skeleton height={24} mb="sm" radius="xs" />
                  <Skeleton height={24} mb="sm" radius="xs" />
                  <Skeleton height={24} mb="sm" radius="xs" />
                  <Skeleton height={24} mb="sm" radius="xs" />
                  <Skeleton height={24} mb="sm" radius="xs" />
                  <Skeleton height={24} mb="sm" radius="xs" />
                  <Skeleton height={24} mb="sm" width="100%" radius="xs" />
                </td>
              </tr>
            ) : filteredTableData?.length ? (
              sortCustomers(filteredTableData, sortingRule).map((d) => (
                <tr
                  key={d.customerId}
                  className={classNames({
                    [classes.opacity]: excludedCustomersId.includes(d.customerId),
                    [classes.verticalAlignment]: showedGuardianId === d.customerId,
                  })}
                >
                  <td
                    onClick={() => {
                      setShowedGuardianId(showedGuardianId === d.customerId ? null : d.customerId);
                      setChildInfoShowed(!childInfoShowed);
                      trackAction(Actions.ENROL_CUSTOMER_SELECT);
                    }}
                  >
                    <Flex direction="column" maw={'fit-content'}>
                      <Text
                        className={classNames(classes.customerName, {
                          [classes.selectedCustomerName]: d.customerId === showedGuardianId,
                        })}
                      >
                        {d.customerFullName}
                      </Text>
                      {d.customerId === showedGuardianId &&
                        d.childrenNames?.map((childName: string, index) => {
                          return (
                            <Flex align="center" mt={8} key={`${d.customerId}-child-${index}`}>
                              <Person weight="fill" size={20} color={theme.colors.gray[5]} />{' '}
                              <Text ml="4px">{childName}</Text>
                            </Flex>
                          );
                        })}
                    </Flex>
                  </td>
                  <td>
                    <CheckCircle
                      role="img"
                      size={20}
                      weight="fill"
                      color={theme.colors.lime[8]}
                      aria-label="Marketing Opt In"
                    />
                  </td>
                  <td>
                    {excludedCustomersId.includes(d.customerId) ? (
                      <Button
                        variant="subtle"
                        className={classes.undoBtn}
                        title={`undo delete ${d.customerFullName}`}
                        onClick={() => {
                          setExcludedCustomersId((prev) =>
                            prev.filter((existingCustomers) => existingCustomers !== d.customerId),
                          );
                          trackAction(Actions.ENROL_CUSTOMER_READD);
                        }}
                      >
                        Undo
                      </Button>
                    ) : (
                      <ActionIcon
                        variant="subtle"
                        onClick={() => {
                          setExcludedCustomersId((prev) => [...prev, d.customerId]);
                          trackAction(Actions.ENROL_CUSTOMER_REMOVE);
                        }}
                        title={`delete ${d.customerFullName}`}
                      >
                        <Trash color={theme.colors.red[8]} size={18} weight="fill" />
                      </ActionIcon>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="tdNoResult" colSpan={4}>
                  <Center className={classes.noResult}>
                    <Text ta="center" fw={600} c={theme.colors.gray[5]}>
                      No customers have given their marketing consent.
                      <br />
                      Once they do, they will appear here.
                    </Text>
                  </Center>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Box>
      {showSort && (
        <SortSettings
          showSort={showSort}
          setShowSort={setShowSort}
          sortingRule={sortingRule}
          setSortingRule={setSortingRule}
          isMobile={isMobile}
        />
      )}
    </>
  );
};

export default CustomerSummary;
