import { FC } from 'react';
import { Title, Button, Text, Box } from '@mantine/core';
import Link from 'next/link';
import classes from './RegistrationForm.module.scss';

export const RegistrationError: FC = () => {
  return (
    <Box style={{ textAlign: 'center', padding: '0 12px 6px' }}>
      <Title order={3} mb="sm">
        Registration issue
      </Title>
      <Text role="alert">
        We&#8217;re sorry, we were unable to create your account. If you already registered and
        forgot your password you can reset it below or contact{' '}
        <Link href="mailto:support@bookpebble.co.uk">support@bookpebble.co.uk</Link>
      </Text>
      <Button
        component="a"
        href="/forgot-password"
        className={classes.pinkButton}
        style={{ width: '100%', margin: '24px 0 36px' }}
        radius="lg"
        size="lg"
      >
        Reset password
      </Button>
    </Box>
  );
};
