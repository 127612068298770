import { gql } from '@apollo/client';

const SupplierBookingRequestCreate = gql`
  mutation supplierBookingRequestCreate($activityId: UUID!, $emails: [String]!) {
    supplierBookingRequestCreate(activityId: $activityId, emails: $emails) {
      sent
    }
  }
`;

export default SupplierBookingRequestCreate;
