import { gql } from '@apollo/client';

export const deleteActivityMutation = gql`
  mutation activityDelete($id: UUID!) {
    activityDelete(id: $id) {
      deleted
    }
  }
`;

export const publishActivityMutation = gql`
  mutation activityPublish($input: ActivityPublishInput!) {
    activityPublish(input: $input) {
      activity {
        id
        name
        status
      }
    }
  }
`;

export const supplierBookingRequestCreateMutation = gql`
  mutation SupplierBookingRequestCreate(
    $activityId: UUID!
    $emails: [String]!
    $optIn: Boolean!
    $customMessage: String
    $sendAt: [Date]
  ) {
    supplierBookingRequestCreate(
      activityId: $activityId
      emails: $emails
      optIn: $optIn
      customMessage: $customMessage
      sendAt: $sendAt
    ) {
      sent
      invitedEmails
    }
  }
`;
