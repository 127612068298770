import { ActionIcon, Button, Text } from '@mantine/core';
import classes from './EmailAddition.module.scss';
import { PebbleTextInput } from 'components/common';
import { Fragment } from 'react';
import classNames from 'classnames';
import { Actions, trackAction } from 'utils/amplitude';
import { Plus, XCircle } from '@phosphor-icons/react';

interface IEmailAdditionProps {
  emailInputs: string[];
  setEmailInputs(val: string[]): void;
  emailErrors: { [index: number]: string } | null;
  setEmailErrors(val: { [index: number]: string } | null): void;
  manualScreen?: boolean;
}

const EmailAddition: React.FC<IEmailAdditionProps> = ({
  setEmailInputs,
  emailInputs,
  emailErrors,
  setEmailErrors,
  manualScreen,
}: IEmailAdditionProps) => {
  const handleAddEmailInput = () => {
    const isLastEmailEmpty = emailInputs[emailInputs.length - 1] === '';

    if (isLastEmailEmpty) {
      setEmailErrors({ ...emailErrors, [emailInputs.length - 1]: 'Please enter an email address' });
    } else {
      setEmailInputs([...emailInputs, '']);
      setEmailErrors(null);
    }
  };

  const handleEmailChange = (value: string, index: number) => {
    const updatedEmails = emailInputs.map((email, i) => (i === index ? value : email));
    setEmailInputs(updatedEmails);

    if (emailErrors?.[index]) {
      const updatedErrors = { ...emailErrors, [index]: '' };

      const areAllErrorsCleared = Object.values(updatedErrors).every((error) => error === '');

      setEmailErrors(areAllErrorsCleared ? null : updatedErrors);
    }
  };

  const handleRemoveEmailInput = (index: number) => {
    if (emailInputs.length === 1) return;
    const updatedEmails = emailInputs.filter((_email, i) => index !== i);
    setEmailInputs(updatedEmails);
  };

  return (
    <div>
      {!manualScreen && <Text className={classes.subheader}>Add customer</Text>}
      {emailInputs.map((email, index) => (
        <Fragment key={index}>
          <PebbleTextInput
            data-cy={`customer-email-field-${index + 1}`}
            required
            placeholder="Customer email"
            radius="lg"
            size="lg"
            className={classNames(classes.textInput, {
              [classes.inputWithDeleteBtn]: emailInputs.length > 1,
            })}
            value={email}
            onChange={(event) => handleEmailChange(event.target.value, index)}
            type="email"
            rightSection={
              emailInputs.length > 1 && (
                <ActionIcon
                  variant="transparent"
                  title={`clear input field ${index + 1}`}
                  className={classes.crossIcon}
                  onClick={() => handleRemoveEmailInput(index)}
                >
                  <XCircle size={28} weight="fill" color="#7f839b" />
                </ActionIcon>
              )
            }
            error={emailErrors && emailErrors[index]}
            onBlur={() => {
              trackAction(Actions.ENROL_ADDITIONAL_CUSTOMERS_EMAIL);
            }}
          />
        </Fragment>
      ))}

      <Button
        leftSection={<Plus weight="bold" size={24} />}
        className={classes.addCustomerButton}
        onClick={() => {
          handleAddEmailInput();
          trackAction(Actions.ENROL_ADD_CUSTOMER);
        }}
      >
        Add Customer
      </Button>
    </div>
  );
};

export default EmailAddition;
