import { FC } from 'react';
import { Button, ButtonProps, useMantineTheme } from '@mantine/core';
import classes from './ClearAllButton.module.scss';
import { useMediaQuery } from '@mantine/hooks';
import { BookingsViewEnum } from 'enums';
interface IClearAllButton extends ButtonProps {
  onClick(): void;
  bookingsView?: BookingsViewEnum;
  filterComponent?: string | null;
}

export const ClearAllButton: FC<IClearAllButton> = ({
  onClick,
  bookingsView,
  filterComponent,
  ...rest
}) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);

  const getClearButtonLabel = () => {
    if (isMobile) {
      return filterComponent === null ? 'Clear all' : 'Clear';
    } else {
      return isMobile ? 'Clear all' : 'Clear';
    }
  };

  return (
    <Button variant="default" className={classes.clearButton} onClick={onClick} {...rest}>
      {getClearButtonLabel()}
    </Button>
  );
};
