import { gql } from '@apollo/client';

const GetActivitySessionInfo = gql`
  query activitiesBySupplier($supplierId: UUID!, $activityId: UUID) {
    activitiesBySupplier(supplierId: $supplierId, activityId: $activityId) {
      activities {
        id
        name
        slug
        blocks {
          id
          sessions {
            id
            date
            date
            startTime
            endTime
            spotsLeft
          }
        }
        sessions {
          id
          date
          date
          startTime
          endTime
          spotsLeft
        }
        tickets {
          block {
            id
            name
            description
            price
            capacity
          }
          individual {
            id
            name
            description
            price
            capacity
          }
        }
      }
    }
  }
`;

export default GetActivitySessionInfo;
