import { SimpleGrid, useMantineTheme } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { CreateTermForm } from './components/CreateTermForm';
import { CreatedTerms } from './components/CreatedTerms';
import { PageHeader } from '../../PageHeader/PageHeader';
import { Term, TermCreateInput } from 'types';
import { addTerm, deleteTerm } from './utils/terms';
import { useState, useMemo } from 'react';
import classes from './TermDates.module.scss';
import { CheckCircle, XCircle } from '@phosphor-icons/react';

interface ITermDates {
  supplierId: string;
  token: string;
  terms: Term[];
}

export const TermDates: React.FC<ITermDates> = ({ terms, supplierId, token }) => {
  const theme = useMantineTheme();
  const [addLoading, setAddLoading] = useState(false);
  const [newTerms, setNewTerms] = useState<Term[]>([]);
  const [deletedTermIds, setDeletedTermIds] = useState<string[]>([]);
  const handleAddTerm = async (term: TermCreateInput) => {
    setAddLoading(true);
    try {
      const newTerm = await addTerm(term, token);
      setNewTerms((prev) => [newTerm, ...prev]);
      showNotification({
        title: `${term.name} successfully added`,
        message: 'You can now view details below',
        icon: <CheckCircle size={30} weight="fill" color={theme.colors.lime[8]} />,
        classNames: {
          root: classes.notification,
          title: classes.notificationTitle,
          description: classes.notificationMessage,
          closeButton: classes.notificationClose,
          icon: classes.notificationIcon,
        },
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      const termNameExistsError = e.message === 'TERM_WITH_THIS_NAME_ALREADY_EXISTS';
      showNotification({
        title: termNameExistsError ? 'Term names must be unique.' : 'Sorry, something went wrong.',
        message: termNameExistsError
          ? `Please pick a term name you haven't used before.`
          : 'Please try again.',
        icon: <XCircle weight="fill" color={theme.colors.red[6]} size={30} />,
        color: 'white',
      });
    } finally {
      setAddLoading(false);
    }
  };

  const handleDeleteTerm = async (termId: string) => {
    try {
      await deleteTerm(termId, token);
      setDeletedTermIds((prev) => [termId, ...prev]);
    } catch (e) {
      showNotification({
        title: 'Sorry, something went wrong.',
        message: 'Please try again.',
        icon: <XCircle weight="fill" color={theme.colors.red[6]} size={30} />,
        color: 'white',
      });
    } finally {
      setAddLoading(false);
    }
  };

  const termsToDisplay = useMemo(() => {
    if (terms) {
      const allAddedTerms = [...newTerms, ...terms];
      return allAddedTerms.filter((term) => {
        return !deletedTermIds.includes(term.id);
      });
    } else {
      return [];
    }
  }, [newTerms, terms, deletedTermIds]);

  return (
    <>
      <PageHeader
        title="Term dates"
        intro={
          <div data-testid="page-description">
            Setup term dates to use as a template when adding activities.
          </div>
        }
      />
      <SimpleGrid
        cols={{ base: 1, lg: 2 }}
        spacing={{ base: 'sm', lg: 'xl' }}
        className={classes.termsContainer}
      >
        <CreateTermForm onAddTerm={handleAddTerm} supplierId={supplierId} loading={addLoading} />
        <CreatedTerms terms={termsToDisplay} loading={addLoading} onDeleteTerm={handleDeleteTerm} />
      </SimpleGrid>
    </>
  );
};
