import { gql } from '@apollo/client';

const MoveAttendeeBlock = gql`
  mutation moveAttendeeBlock($input: MoveAttendeeBlockInputType!) {
    moveAttendeeBlock(input: $input) {
      success
    }
  }
`;

export default MoveAttendeeBlock;
