import { Card, Stack } from '@mantine/core';
import classes from './Activities.module.scss';
import { PebbleRadio, PebbleRadioGroup } from 'components/common';
import { DynamicFilterOption } from 'interfaces';
import { Fragment } from 'react';
import { Actions } from 'utils/amplitude';
import { BookingsViewEnum } from 'enums';
import { getMobileFilterTrackEvent } from '../FiltersDrawer';

interface IActivitiesFilter {
  activityFilters: DynamicFilterOption[];
  selectedActivity: string;
  onActivityChange(val: string): void;
  bookingsView?: BookingsViewEnum;
}

const ActivitiesFilter: React.FC<IActivitiesFilter> = ({
  activityFilters,
  selectedActivity,
  onActivityChange,
  bookingsView,
}) => {
  return (
    <Card pb={0} className={classes.filterCard}>
      <Stack>
        <PebbleRadioGroup
          name="categories filter option"
          label="Activities"
          onChange={(val) => {
            onActivityChange(val);
            getMobileFilterTrackEvent(
              Actions.SUB_ACTIVITY_FILTER_MOBILE,
              Actions.BOOKING_ACTIVITY_FILTER_MOBILE,
              Actions.SCHEDULED_ACTIVITY_FILTER_MOBILE,
              bookingsView,
            );
          }}
          apply_filter_styling={'true'}
          value={selectedActivity}
        >
          {activityFilters.map((activity) => (
            <Fragment key={activity.value}>
              <PebbleRadio
                labelPosition="left"
                width={'100%'}
                key={activity.value}
                value={activity.value}
                label={activity.label}
                apply_filter_styling={'true'}
              />
            </Fragment>
          ))}
        </PebbleRadioGroup>
      </Stack>
    </Card>
  );
};

export default ActivitiesFilter;
