import { gql } from '@apollo/client';

const CreateMarketplaceRefund = gql`
  mutation createMarketplaceRefund($input: CreateMarketplaceRefundInputType!) {
    createMarketplaceRefund(input: $input) {
      refund {
        id
        status
        # amount
        # stripeRefundId
        # transferReversedAmount
        # stripeTransferReversalId
        # reason
        # reasonDetails
        # bookingSessions {
        #   id
        # }
        # booking {
        #   id
        #   refundAllowance
        # }
      }
    }
  }
`;

export default CreateMarketplaceRefund;
