import { FC } from 'react';
import { Button, Card, Group, Text, Title } from '@mantine/core';
import { Term } from 'types';
import dayjs from 'dayjs';
import { Actions, trackAction } from 'utils/amplitude';
import classes from './TermCard.module.scss';
import classNames from 'classnames';
import { X } from '@phosphor-icons/react';

interface ITermCardProps {
  term: Term;
  isNewlyCreated: boolean;
  onDeleteClick(): void;
  setShowViewingModal(bool: boolean): void;
  setChosenTerm(term: Term): void;
}

export const TermCard: FC<ITermCardProps> = (props) => {
  const { isNewlyCreated, onDeleteClick, setShowViewingModal, term, setChosenTerm } = props;

  // TODO: add .new css class when createdAt is within 2h
  return (
    <Card shadow="sm" className={classNames(classes.card, { [classes.new]: isNewlyCreated })}>
      <Title order={2} className={classes.title}>
        {term.name}
      </Title>
      <Group gap={5} wrap="nowrap" align="flex-start">
        <Text className={classes.title}> {dayjs(term.startDate).format('DD | MM | YY')}</Text>
        <Text className={classes.dateDivider}> to </Text>
        <Text className={classes.title}>{dayjs(term.endDate).format('DD | MM | YY')}</Text>
      </Group>
      <Group mt="xs" gap="xs">
        <Button
          radius="xl"
          size="sm"
          className={classes.primaryButton}
          onClick={() => {
            setShowViewingModal(true);
            setChosenTerm(term);
            trackAction(Actions.VIEW_TERM);
          }}
        >
          View
        </Button>
        <Button
          radius="xl"
          size="sm"
          className={classes.secondaryButton}
          color="red"
          variant="light"
          leftSection={<X weight="bold" size={16} />}
          onClick={() => {
            onDeleteClick();
            trackAction(Actions.DELETE_TERM);
          }}
        >
          Delete
        </Button>
      </Group>
    </Card>
  );
};
