import { FC } from 'react';
import { SegmentedControl, Text, Stack } from '@mantine/core';
import { BookingsSortByEnum } from 'enums';
import classes from './SortMenu.module.scss';
import classNames from 'classnames';

export interface ISortMenu {
  activeSortKey: BookingsSortByEnum;
  onSortChange(key: string): void;
}

export const SortMenu: FC<ISortMenu> = ({ activeSortKey, onSortChange }) => {
  return (
    <Stack>
      <Text
        className={classNames(classes.label, {
          [classes.labelActive]: activeSortKey.startsWith('session_date'),
        })}
      >
        Date
      </Text>
      <SegmentedControl
        transitionDuration={0}
        radius="lg"
        classNames={{
          label: classes.controlLabel,
          control: classes.control,
        }}
        onChange={onSortChange}
        value={activeSortKey}
        data={[
          {
            label: 'Earliest',
            value: BookingsSortByEnum.SESSION_DATE_DESC as string,
          },
          {
            label: 'Latest',
            value: BookingsSortByEnum.SESSION_DATE_ASC as string,
          },
        ]}
      />
    </Stack>
  );
};
