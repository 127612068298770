import { Button, Flex, Text, useMantineTheme } from '@mantine/core';
import { InviteAttendeeTabsEnum } from 'enums';
import classes from './PreviousActivitiesOrManualScreen.module.scss';
import { useMediaQuery } from '@mantine/hooks';
import { Actions, trackAction } from 'utils/amplitude';
import { CaretRight, EnvelopeSimple } from '@phosphor-icons/react';

interface IPreviousActivitiesOrManualScreenProps {
  setActiveTab(val: InviteAttendeeTabsEnum): void;
}

const PreviousActivitiesOrManualScreen: React.FC<IPreviousActivitiesOrManualScreenProps> = ({
  setActiveTab,
}: IPreviousActivitiesOrManualScreenProps) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);
  return (
    <>
      <Flex direction="column" align="center">
        <EnvelopeSimple
          weight="duotone"
          className={classes.envelopeIcon}
          size={48}
          color={theme.colors.blue[6]}
        />
        <Text className={classes.copy}>Invite Customers</Text>
        <Button
          mb="lg"
          classNames={{ root: classes.inviteButtons, inner: classes.inviteButtonsInner }}
          onClick={() => {
            setActiveTab(InviteAttendeeTabsEnum.PREVIOUS_ACTIVITIES_SELECTION);
            trackAction(Actions.ENROL_PREVIOUS);
          }}
          rightSection={<CaretRight size={20} weight="bold" />}
          fullWidth={isMobile}
        >
          From previous activities
        </Button>
      </Flex>

      <div className={classes.dividerContainer}>
        <div className={classes.divider} />
        <Text fw={600} c="#4c5471" className={classes.text}>
          OR
        </Text>
        <div className={classes.divider} />
      </div>

      <Flex direction="column" align="center">
        <Button
          mt="lg"
          classNames={{ root: classes.inviteButtons, inner: classes.inviteButtonsInner }}
          onClick={() => {
            setActiveTab(InviteAttendeeTabsEnum.MANUALLY_ADDED_SCREEN);
            trackAction(Actions.ENROL_MANUAL);
          }}
          rightSection={<CaretRight size={20} weight="bold" />}
          fullWidth={isMobile}
        >
          Add customers manually
        </Button>
      </Flex>
    </>
  );
};

export default PreviousActivitiesOrManualScreen;
