import { gql } from '@apollo/client';

const UpdateDiscount = gql`
  mutation promotionUpdateMutation($input: PromotionUpdateInput) {
    promotionUpdate(input: $input) {
      promotion {
        id
        status
      }
    }
  }
`;

export default UpdateDiscount;
