import { Anchor, Card, Divider, Flex, Stack, Text } from '@mantine/core';
import classes from './TimesFilter.module.scss';
import { PebbleCheckbox, PebbleCheckboxGroup } from 'components/common';
import { DynamicFilterOption } from 'interfaces';
import classNames from 'classnames';
import { Fragment } from 'react';
import { BookingsViewEnum } from 'enums';
import { Actions, trackAction } from 'utils/amplitude';

interface ITimesFilter {
  setSelectedTimesFilter?(val: string[]): void;
  selectedTimes?: string[];
  timeFilters?: DynamicFilterOption[];
  bookingsView?: BookingsViewEnum;
}

const TimesFilter: React.FC<ITimesFilter> = ({
  setSelectedTimesFilter,
  selectedTimes,
  timeFilters,
  bookingsView,
}) => {
  const isIndeterminate =
    selectedTimes?.length === 0 || selectedTimes?.length === timeFilters?.length;

  if (!setSelectedTimesFilter || !selectedTimes || timeFilters?.length === 0) {
    return;
  }

  return (
    <Card className={classes.filterCard}>
      <Stack>
        <Flex justify={isIndeterminate ? 'start' : 'space-between'}>
          <Text fw={700} size="18px">
            Times
          </Text>
          {!isIndeterminate && (
            <Anchor
              component="button"
              onClick={() => {
                setSelectedTimesFilter([]);
                const action =
                  bookingsView === BookingsViewEnum.SUBSCRIPTION_BOOKINGS
                    ? Actions.SUB_TIME_FILTER_MOBILE
                    : Actions.BOOKING_TIME_FILTER_MOBILE;
                trackAction(action);
              }}
              style={{
                fontSize: '16px',
                fontWeight: 700,
                color: 'var(--mantine-color-blue-6)',
                textDecoration: 'underline',
              }}
            >
              Select all
            </Anchor>
          )}
        </Flex>
        <PebbleCheckboxGroup value={selectedTimes} onChange={setSelectedTimesFilter}>
          {timeFilters?.map((option: DynamicFilterOption) => {
            return (
              <Fragment key={option.value}>
                <PebbleCheckbox
                  key={option.value}
                  value={option.value || option.label}
                  label={option.label}
                  indeterminate={isIndeterminate}
                  classNames={{
                    root: classes.checkboxRoot,
                    icon: classNames(classes.checkboxIcon, {
                      [classes.checkboxIconChecked]: !isIndeterminate,
                    }),
                  }}
                  onClick={(event) => {
                    if (event.currentTarget.checked) {
                      const action =
                        bookingsView === BookingsViewEnum.SUBSCRIPTION_BOOKINGS
                          ? Actions.SUB_TIME_FILTER_MOBILE
                          : Actions.BOOKING_TIME_FILTER_MOBILE;
                      trackAction(action);
                    }
                  }}
                  fullWidth
                />
                <Divider c="#F1F1F2" />
              </Fragment>
            );
          })}
        </PebbleCheckboxGroup>
      </Stack>
    </Card>
  );
};

export default TimesFilter;
