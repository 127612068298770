import { gql } from '@apollo/client';

const paymentCreate = gql`
  mutation supplierPaymentAccountCreate($supplierId: UUID!, $businessType: BusinessTypeEnum!) {
    supplierPaymentAccountCreate(supplierId: $supplierId, businessType: $businessType) {
      supplierPaymentAccount {
        paymentAccountId
        paymentAccountOnboardingUrl
        status
      }
    }
  }
`;

export default paymentCreate;
