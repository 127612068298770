import { gql } from '@apollo/client';

const SupplierAllPaidActivities = gql`
  query supplierAllPaidActivities($supplierId: UUID!, $searchTerms: [String]) {
    supplierAllPaidActivities(supplierId: $supplierId, searchTerms: $searchTerms) {
      id
      name
      isOnline
      ageRange
      maxDate
      minDate
      location {
        addressLine1
        addressLine2
        postCode
        city
      }
    }
  }
`;

export default SupplierAllPaidActivities;
