import { gql } from '@apollo/client';

const IgnoreMarketplacePaymentCharge = gql`
  mutation ignoreMarketplacePaymentCharge($paymentId: UUID!) {
    ignoreMarketplacePaymentCharge(paymentId: $paymentId) {
      succeeded
    }
  }
`;

export default IgnoreMarketplacePaymentCharge;
