import { gql } from '@apollo/client';

export const WaitlistByActivityQuery = gql`
  query WaitlistByActivityQuery(
    $supplierId: UUID!
    $activityId: UUID!
    $dateStart: DateTime
    $dateEnd: DateTime
    $timeFilter: [String]
    $weekdaysFilter: [String]
  ) {
    activitiesBySupplier(supplierId: $supplierId, activityId: $activityId) {
      activities {
        id
        name
        minDate
        maxDate
        times {
          startTime
          endTime
        }
        schedules {
          startTime
          endTime
        }
        activityType
      }
    }
    waitlistByActivity(
      activityId: $activityId
      dateStart: $dateStart
      dateEnd: $dateEnd
      timeFilter: $timeFilter
      weekdaysFilter: $weekdaysFilter
    ) {
      waitlist {
        id
        createdAt
        email
        phoneNumber
        fullName
        attendeeCount
        checkoutKey
        activity {
          id
          name
          maxDate
          minDate
          activityType
          bookingType
          times {
            startTime
            endTime
          }
          schedules {
            startTime
            endTime
          }
          location {
            id
            addressLine1
            addressLine2
            city
            postCode
          }
        }
        activityClass {
          id
          name
        }
        session {
          id
          date
          startTime
          endTime
          capacity
          bookingCount
          activity {
            id
            name
            location {
              id
              addressLine1
              addressLine2
              city
              postCode
            }
          }
        }
        weekdays
        invitedToBookAt
        activityTime
        activityDate
      }
      filters {
        times {
          name
          value
        }
        weekdays {
          name
          value
        }
      }
    }
  }
`;

export default WaitlistByActivityQuery;
