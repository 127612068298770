import { gql } from '@apollo/client';

export const RefundReceiptQuery = gql`
  query getRefundReceipt($supplierId: UUID!, $bookingSessionIds: [UUID]!, $refundAmount: Int) {
    getRefundReceipt(
      supplierId: $supplierId
      bookingSessionIds: $bookingSessionIds
      refundAmount: $refundAmount
    ) {
      booking {
        id
        originalAmount
        finalAmount
        discount
        creationType
        refunds {
          id
        }
        refundsAmount
        isRefundable
        refundAllowance
        activity {
          name
          location {
            addressLine1
            addressLine2
            city
            country
            postCode
          }
        }
        bookedClasses {
          id
          name
          bookedTickets {
            ticket {
              id
              name
              description
              ticketType
              price
              pricingPolicy
            }
            numberOfSessions
            ticketNameAtBooking
            ticketPriceAtBooking
            ticketPricingPolicyAtBooking
            numberOfSessions
            hasFailedPayment
            addonsByAttendee {
              attendee {
                fullName
              }
              bookingAddons {
                name
                price
                quantity
              }
              sessionAddons {
                name
                price
                quantity
              }
            }
            attendees {
              id
              fullName
              guest {
                id
                fullName
              }
              guardian {
                id
                fullName
              }
            }
            sessions {
              id
              sessionStart
              sessionEnd
              isFreeTrialSession
              isPaidTrialSession
            }
          }
        }
        discount
        promotion {
          code
          pebblePaysForPromotions
        }
        latestInvoice {
          paymentAmount
        }
      }
      refundableSessions {
        id
        sessionStart
        sessionEnd
        isFreeTrialSession
        isPaidTrialSession
      }
      refundAmount
    }
  }
`;

export default RefundReceiptQuery;
