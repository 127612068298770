import { FC, useState } from 'react';
import { Container, Grid, Overlay } from '@mantine/core';
import { PageHeader } from 'components';
import Cards from './components/Card/Card';
import ActivityFinancialReport from './components/ActivityFinancialReport/ActivityFinancialReport';
import Setup from './components/Setup/Setup';
import UpcomingClasses from './components/UpcomingClasses/UpcomingClasses';
import ClassCapacity from './components/ClassCapacity/ClassCapacity';
import classes from './Home.module.scss';

interface IHome {
  token: string;
  supplierId: string;
  supplierName: string;
  paymentStatusCompleted: boolean;
}

export enum ComponentTypes {
  SETUP = 'setup',
  UPCOMING = 'upcoming',
  CAPACITY = 'capacity',
  FINANCE = 'finance',
  PERFORMANCE = 'performance',
}

export const Home: FC<IHome> = (props) => {
  const { token, supplierId, supplierName, paymentStatusCompleted } = props;

  const [setupComplete, setSetupComplete] = useState<boolean | undefined>(true);

  const [dateFilterActive, setDateFilterActive] = useState(false);

  return (
    <>
      <PageHeader title={'Dashboard'} />
      <Container className={classes.container} fluid>
        <Grid pb="xl" columns={12}>
          <Grid.Col span={12} hidden={setupComplete}>
            <Cards section={ComponentTypes.SETUP}>
              <Setup token={token} supplierId={supplierId} setSetupComplete={setSetupComplete} />
            </Cards>
          </Grid.Col>
          <Grid.Col
            span={{
              base: 12,
              md: 6,
              lg: 4,
            }}
          >
            <Cards section={ComponentTypes.UPCOMING}>
              <UpcomingClasses token={token} supplierId={supplierId} />
            </Cards>
          </Grid.Col>
          <Grid.Col
            span={{
              base: 12,
              md: 6,
              lg: 8,
            }}
          >
            <Cards section={ComponentTypes.CAPACITY}>
              <ClassCapacity token={token} supplierId={supplierId} />
            </Cards>
          </Grid.Col>
          <Grid.Col span={12}>
            <Cards section={ComponentTypes.FINANCE}>
              {paymentStatusCompleted && (
                <ActivityFinancialReport
                  token={token}
                  supplierId={supplierId}
                  supplierName={supplierName}
                  dateFilterActive={dateFilterActive}
                  setDateFilterActive={setDateFilterActive}
                />
              )}
              {!paymentStatusCompleted && <div>Please complete setting up your Stripe account</div>}
            </Cards>
            {dateFilterActive && <Overlay zIndex={2} opacity={0.4} color="#000" fixed />}
          </Grid.Col>
        </Grid>
      </Container>
    </>
  );
};
