export enum ScheduledActivityViewingModeEnum {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  READ = 'READ',
}

export enum ActivityBookingTypeEnum {
  INSTANT = 'INSTANT',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

export enum ActivityBookingOccurrenceEnum {
  BLOCK = 'BLOCK',
  INDIVIDUAL = 'INDIVIDUAL',
  BLOCK_AND_INDIVIDUAL = 'BLOCK_AND_INDIVIDUAL',
}

export enum ActivityLocationTypeEnum {
  IN_PERSON = 'in-person',
  ONLINE = 'online',
}

export enum FrameTypesEnum {
  SUPPLIER = 'supplier',
  ACTIVITY = 'activity',
  WIDGET = 'widget',
}

export enum CustomQuestionTypeEnum {
  YES_NO = 'YES_NO',
  YES_NO_INPUT = 'YES_NO_INPUT',
  TEXT_INPUT = 'TEXT_INPUT',
}

export enum DefaultBookingQuestionIdsEnum {
  PHOTO_VIDEO_CONSENT_ID = '78b72036-9cb4-44e4-81a9-4e65c9160f12',
  MEDICAL_INFORMATION_ID = '6104691b-5900-4fc2-bdc5-1eced46f9c45',
}

export enum BookingSessionAddedByEnum {
  SUPPLIER_UNPAID = 'SUPPLIER_UNPAID',
  SUPPLIER_MOVED = 'SUPPLIER_MOVED',
}

export enum TabValueEnum {
  DETAILS = 'details',
  SCHEDULE = 'schedule',
  CAPACITY = 'capacity',
  TICKETS = 'tickets',
  BOOKING_QUESTIONS = 'booking_questions',
  ADD_ONS = 'add_ons',
  TEAM = 'team',
  CONTACT = 'contact',
  COMMS = 'comms',
}

export enum ImageUploaderOptionsEnum {
  LOGO = 'logo',
  PROVIDER = 'provider',
  ACTIVITY = 'activity',
  COMMS = 'comms',
}

export enum ActivitySubscriptionTrialTypeEnum {
  NO_TRIAL = 'NO_TRIAL',
  FREE_TRIAL = 'FREE_TRIAL',
  PAID_TRIAL = 'PAID_TRIAL',
}

export enum DaysOfWeekEnum {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
}

export enum BlockBookingsTypeEnum {
  ALL_DAYS = 'all_days',
  SAME_RECURRING = 'recurring',
}

export enum BlockTrialTypeEnum {
  NO_TRIAL = 'NO_TRIAL',
  FREE_TRIAL = 'FREE_TRIAL',
  PAID_TRIAL = 'PAID_TRIAL',
}

export enum LocationCountryEnum {
  'United Kingdom' = 'UK',
}

export enum ActivityTypeEnum {
  ONE_OFF = 'ONE_OFF',
  BLOCK = 'BLOCK',
  ONE_OFF_AND_BLOCK = 'ONE_OFF_AND_BLOCK',
  SUBSCRIPTION = 'SUBSCRIPTION',
}

export enum AccountStatus {
  'NOT_STARTED' = 'NOT_STARTED',
  'IN_PROGRESS' = 'IN_PROGRESS',
  'COMPLETED' = 'COMPLETED',
}

export enum MarketplaceActivityBookingType {
  'INSTANT' = 'Instant booking',
}

export enum ActivityStatusEnum {
  'PUBLISHED' = 'PUBLISHED',
  'UNPUBLISHED' = 'UNPUBLISHED',
  'DRAFT' = 'DRAFT',
}

export enum TermDateFilterEnum {
  'PAST' = 'PAST',
  'UPCOMING' = 'UPCOMING',
}

export enum AgeRangeEnum {
  '0-6 months' = '0-6',
  '6-12 months' = '6-12',
  '1-2 years' = '12-24',
  '2-3 years' = '24-36',
  '3-4 years' = '36-48',
  '4-5 years' = '48-60',
}

export enum DistanceEnum {
  '1 mile' = '1',
  '2 miles' = '2',
  '5 miles' = '5',
  '10 miles' = '10',
}

export enum ValidFiltersEnum {
  ageEnd,
  ageStart,
  distance,
  dayOfTheWeek,
  latitude,
  longitude,
}

export enum BusinessTypeEnum {
  INDIVIDUAL = 'INDIVIDUAL',
  COMPANY = 'COMPANY',
}

export enum MarketplaceActivityBookingSessionStatusEnum {
  CONFIRMED = 'CONFIRMED',
  CANCELLED = 'CANCELLED',
}

export enum MembershipEnum {
  ACTIVITY_REGISTERS = 'ACTIVITY_REGISTERS',
}

export enum BookingsSortByEnum {
  CHILD_ASC = 'child_asc',
  CHILD_DESC = 'child_desc',
  RECEIVED_DATE_ASC = 'received_date_asc',
  RECEIVED_DATE_DESC = 'received_date_desc',
  SESSION_DATE_ASC = 'session_date_asc',
  SESSION_DATE_DESC = 'session_date_desc',
  ACTIVITY_NAME_ASC = 'activity_name_asc',
  ACTIVITY_NAME_DESC = 'activity_name_desc',
  ADDRESS_ASC = 'address_asc',
  ADDRESS_DESC = 'address_desc',
}

export enum PricingPolicyEnum {
  PER_SESSION = 'PER_SESSION',
  BLOCK_FULL_PRICE = 'BLOCK_FULL_PRICE',
  FREE = 'FREE',
  RECURRING = 'RECURRING',
}

export enum SubscriptionLastPaymentStatusEnum {
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
}

export enum BookingCancellationEnum {
  ILLNESS = 'ILLNESS',
  NO_LONGER_NEEDED = 'NO_LONGER_NEEDED',
  OTHER = 'OTHER',
  SUBSCRIPTION_ENDED = 'SUBSCRIPTION_ENDED',
  PARENT_REQUESTED = 'PARENT_REQUESTED',
  MANY_FAILED_PAYMENTS = 'MANY_FAILED_PAYMENTS',
  CIRCUMSTANCE_CHANGE = 'CIRCUMSTANCE_CHANGE',
}

export enum BlockTrialCancellationEnum {
  PARENT_REQUESTED = 'Parent requested',
  MANY_FAILED_PAYMENTS = 'Failed payments',
  CIRCUMSTANCE_CHANGE = 'Circumstance change',
  OTHER = 'Other',
}

export enum SubsInfoAmplitudeLocationEnum {
  'BOOKING_TYPE_SELECTION' = 'Booking type selection page',
  'DETAILS' = 'Details tab',
  'SCHEDULE' = 'Schedule tab',
}

export enum AdditionalInformationEmailStatusEnum {
  'DELIVERED' = 'DELIVERED',
  'READ' = 'READ',
  'UNDELIVERED' = 'UNDELIVERED',
}

export enum AttendeeFiltersEnum {
  ATTENDEE_NAME_REGISTER_SORT = 'attendee_name_register_sort',
  ATTENDEE_LAST_NAME_REGISTER_SORT = 'attendee_last_name_register_sort',
  MEDICAL_REGISTER_SORT = 'medical_register_sort',
  ATTENDANCE_REGISTER_SORT = 'attendance_register_sort',
  PHOTO_REGISTER_SORT = 'photo_register_sort',
  AGE_REGISTER_SORT = 'age_register_sort',
  TICKET_REGISTER_SORT = 'ticket_register_sort',
  ADDON_ONS_REGISTER_SORT = 'addon_ons_register_sort',
  BOOKING_ID_SORT = 'booking_id_sort',
}

export enum IntegrationTabsEnum {
  IFRAME_GENERATOR = 'iframe_generator',
  REVIEWS = 'reviews',
}

export enum PebbleUseEnum {
  BOOKING_MANAGEMENT_SOFTWARE = 'BOOKING_MANAGEMENT_SOFTWARE',
  MARKETPLACE = 'MARKETPLACE',
}

export enum PonchoPayStatusEnum {
  NOT_SET_UP = 'NOT_SET_UP',
  REQUESTED = 'REQUESTED',
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum AddAttendeeTabsEnum {
  'ADD_ATTENDEE_EDUCATION_SCREEN' = 'add_attendee_education_screen',
  'PAID_OR_NON_PAID' = 'paid_or_non_paid',
  'INVITE_ATTENDEE_INFO_SCREEN' = 'invite_attendee_info_screen',
  'BLOCK_OR_INDIVIDUAL_SESSION_SCREEN' = 'block_or_individual_session_screen',
  'INVITE_ATTENDEE_SUCCESS_SCREEN' = 'invite_attendee_success_screen',
  'NON_PAID_CUSTOMER_DETAILS' = 'non_paid_customer_details',
  'NON_PAID_ADD_TICKETS_SCREEN' = 'non_paid_add_tickets_screen',
  'NON_PAID_ADD_ATTENDEE_SCREEN' = 'non_paid_add_attendee_screen',
  'NON_PAID_CONFIRMATION_SCREEN' = 'non_paid_confirmation_screen',
  'NON_PAID_SUCCESS_SCREEN' = 'non_paid_success_screen',
}

export enum MoveAttendeeTabsEnum {
  'INDIVIDUAL_OR_BLOCK_SELECT_SCREEN' = 'individual_or_block_select_screen',
  'ACTIVITY_SELECT_SCREEN' = 'activity_select_screen',
  'SESSION_SELECT_SCREEN' = 'session_select_screen',
  'MOVE_REVIEW_SCREEN' = 'move_review_screen',
  'MOVE_COMPLETE_SCREEN' = 'move_complete_screen',
}

export enum TransferWaitlistTabsEnum {
  'ACTIVITY_SELECT_SCREEN' = 'activity_select_screen',
  'TRANSFER_CONFIRM_SCREEN' = 'transfer_confirm_screen',
  'TRANSFER_COMPLETE_SCREEN' = 'transfer_complete_screen',
}

export enum AddBookingEnum {
  'THIS_SESSION_ONLY' = 'this_session_only',
  'THIS_SESSION_PLUS_REMAINING' = 'this_session_plus_remaining',
}

export enum InviteAttendeeTabsEnum {
  'PREVIOUS_ACTIVITIES_OR_MANUAL' = 'previous_activities_or_manual',
  'PREVIOUS_ACTIVITIES_SELECTION' = 'previous_activities_selection',
  'MANUALLY_ADDED_SCREEN' = 'manually_added-screen',
  'REVIEW_CUSTOMERS_SCREEN' = 'review_customers_screen',
  'INVITE_MESSAGE' = 'invite_message',
  'SUCCESS_SCREEN' = 'success_screen',
}

export enum AddToWaitlistTabsEnum {
  'ADD_TO_WAITLIST_FORM' = 'add_to_waitlist_form',
  'SUCCESS_SCREEN' = 'success_screen',
}

export enum BookingsViewEnum {
  STANDARD_BOOKINGS = 'standard_bookings',
  SUBSCRIPTION_BOOKINGS = 'subscription_bookings',
}

export enum DiscountsDashboardViewEnum {
  DISCOUNTS_VIEW = 'discounts_view',
  REDEMPTIONS_VIEW = 'redemptions_view',
}

export enum CheckoutKeyEnum {
  SESSION = 'SESSION',
  SCHEDULE = 'SCHEDULE',
  BLOCK = 'BLOCK',
  MANUALLY_ADDED = 'MANUALLY_ADDED',
  TRANSFERRED = 'TRANSFERRED',
}

export enum ScheduleViewTypeEnum {
  SCHEDULE_LIST = 'schedule_list',
  SCHEDULE_OVERVIEW = 'schedule_overview',
}

export enum RegisterButtonsDirectionEnum {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export enum AttendeeBookingStatusEnum {
  CONFIRMED = 'CONFIRMED',
  CANCELLED = 'CANCELLED',
  MOVED = 'MOVED',
  PENDING = 'PENDING',
}

export enum ActivityAddOnEnum {
  SESSION_LEVEL = 'SESSION_LEVEL',
  BOOKING_LEVEL = 'BOOKING_LEVEL',
}

export enum AdditionalInfoModalTabsEnum {
  INFO = 'INFO',
  BOOKING_QUESTIONS = 'BOOKING_QUESTIONS',
  ADD_ONS = 'ADD_ONS',
  NOTES = 'NOTES',
}

export enum AttendeeCancellationStepsEnum {
  SELECT_SESSIONS = 'SELECT_SESSIONS',
  ISSUE_REFUND = 'ISSUE_REFUND',
  FULL_BOOKING_SUMMARY = 'FULL_BOOKING_SUMMARY',
  SELECT_CANCELLATION_REASON = 'SELECT_CANCELLATION_REASON',
  MANUALLY_ADDED_ATTENDEE = 'MANUALLY_ADDED_ATTENDEE',
  MOVED_ATTENDEE = 'MOVED_ATTENDEE',
  PEBBLE_PROMOTION_APPLIED = 'PEBBLE_PROMOTION_APPLIED',
  PONCHO_PAY_BOOKING = 'PONCHO_PAY_BOOKING',
  SUBSCRIPTION_BOOKING = 'SUBSCRIPTION_BOOKING',
  REFUND_SUMMARY = 'REFUND_SUMMARY',
  COMPLETE = 'COMPLETE',
}

export enum CancelBlockTrialsStepsEnum {
  FULL_BOOKING_SUMMARY = 'FULL_BOOKING_SUMMARY',
  SELECT_CANCELLATION_REASON = 'SELECT_CANCELLATION_REASON',
  // MANUALLY_ADDED_ATTENDEE = 'MANUALLY_ADDED_ATTENDEE',
  // MOVED_ATTENDEE = 'MOVED_ATTENDEE',
  // PEBBLE_PROMOTION_APPLIED = 'PEBBLE_PROMOTION_APPLIED',
  REFUND_SUMMARY = 'REFUND_SUMMARY',
  COMPLETE = 'COMPLETE',
}

export enum ActivityCreationTypeEnum {
  STANDARD = 'STANDARD',
  UNPAID = 'UNPAID',
  FREE = 'FREE',
  MOVED = 'MOVED',
}

export enum RefundReasonEnum {
  CUSTOMER_REQUESTED = 'Customer requested',
  SESSION_CANCELLED = 'Session cancelled',
  FULL_ACTIVITY_CANCELLED = 'Full activity cancelled',
  OTHER = 'Other',
}

export enum RefundOptionEnum {
  REFUND = 'REFUND',
  NO_REFUND = 'NO_REFUND',
}

export enum MaxTimesRedeemedPerCustomer {
  ONCE = 'once',
  UNLIMITED = 'unlimited',
}

export enum ViewType {
  CREATE = 'create',
  EDIT = 'edit',
}

export enum PromoStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  REDEEMED = 'REDEEMED',
}

export enum PromoFilter {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  EXPIRED = 'EXPIRED',
}

export enum DiscountValueType {
  FIXED = 'Fixed',
  PERCENTAGE = 'Percentage',
}

export enum DiscountValidFormEnum {
  ALL_ACTIVITIES = 'All activities',
  SPECIFIC_ACTIVITIES = 'Specific activities',
  SPECIFIC_ACTIVITY_CATEGORIES = 'Specific activity categories',
}

export enum CustomerEligibilityEnum {
  ALL_CUSTOMERS = 'All customers',
  FIRST_BOOKING_ONLY_PER_SUPPLIER = 'First-time bookers only',
}

export enum TicketTypeEnum {
  SUBSCRIPTION = 'subscription',
  INDIVIDUAL = 'individual',
  BLOCK = 'block',
}

export enum TicketRestrictionTypeEnum {
  BY_DATE = 'BY_DATE',
  BY_FIRST_SESSION_CUTOFF = 'BY_FIRST_SESSION_CUTOFF',
}

export enum ClassesDateFilterEnum {
  'PAST' = 'PAST',
  'UPCOMING' = 'UPCOMING',
  'ALL' = 'ALL',
}

export enum ClassDrawerState {
  'CLOSED' = 'CLOSED',
  'EDIT' = 'EDIT',
  'CREATE' = 'CREATE',
  'CLONE' = 'CLONE',
}

export enum ClassSelectMode {
  'CHECKBOX' = 'CHECKBOX',
  'RADIO' = 'RADIO',
}

export enum MarketplaceTicketTicketTypeChoices {
  BLOCK = 'BLOCK',
  INDIVIDUAL = 'INDIVIDUAL',
  SUBSCRIPTION = 'SUBSCRIPTION',
  MOVE = 'MOVE',
}

export enum AgeType {
  YEARS = 'years',
  MONTHS = 'months',
}

export enum SubscriptionBookingInvoiceStatus {
  PENDING = 'PENDING',
  PAID = 'PAID',
  FAILED = 'FAILED',
  TRANSFERRED = 'TRANSFERRED',
  CANCELLED = 'CANCELLED',
}

export enum StripePaymentStatus {
  REQUIRES_PAYMENT_METHOD = 'REQUIRES_PAYMENT_METHOD',
  REQUIRES_CONFIRMATION = 'REQUIRES_CONFIRMATION',
  REQUIRES_ACTION = 'REQUIRES_ACTION',
  PROCESSING = 'PROCESSING',
  REQUIRES_CAPTURE = 'REQUIRES_CAPTURE',
  CAPTURED = 'CAPTURED',
  COMPLETED = 'COMPLETED',
  SUCCEEDED = 'SUCCEEDED',
  IN_BANK = 'IN_BANK',
  CANCELED = 'CANCELED',
}

export enum CustomersSortByEnum {
  CUSTOMER_NAME_ASC = 'customer_name_asc',
  CUSTOMER_NAME_DESC = 'customer_name_desc',
  LATEST_BOOKING_ASC = 'latest_booking_date_asc',
  LATEST_BOOKING_DESC = 'latest_booking_date_desc',
  TOTAL_BOOKINGS_ASC = 'total_bookings_asc',
  TOTAL_BOOKINGS_DESC = 'total_bookings_desc',
  TOTAL_SESSIONS_ASC = 'total_sessions_asc',
  TOTAL_SESSIONS_DESC = 'total_sessions_desc',
  AVG_SPEND_ASC = 'average_spend_asc',
  AVG_SPEND_DESC = 'average_spend_desc',
  TOTAL_SPEND_ASC = 'total_spent_asc',
  TOTAL_SPEND_DESC = 'total_spent_desc',
}
