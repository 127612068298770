import { gql } from '@apollo/client';

const UpdatePromoPublishStatus = gql`
  mutation promotionUpdate($input: PromotionUpdateInput) {
    promotionUpdate(input: $input) {
      promotion {
        id
        status
      }
    }
  }
`;

export default UpdatePromoPublishStatus;
