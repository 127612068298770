import { gql } from '@apollo/client';

const SupplierOnboarding = gql`
  query SupplierOnboarding($supplierId: UUID!) {
    supplierOnboarding(supplierId: $supplierId) {
      complete
      tasks {
        complete
        name
      }
    }
  }
`;

export default SupplierOnboarding;
