import { gql } from '@apollo/client';

const MoveAttendeeSession = gql`
  mutation moveAttendeeSession($input: MoveAttendeeSessionInputType!) {
    moveAttendeeSession(input: $input) {
      success
    }
  }
`;

export default MoveAttendeeSession;
