import { FC, useState } from 'react';
import { Center, Stack, Text, Title } from '@mantine/core';
import Image from 'next/legacy/image';

import { Term } from 'types';

import { LoadingCards } from 'components/LoadingCards';
import { TermCard } from '../TermCard';
import { isNewlyCreated } from '../../../../../utils/isNewlyCreated';
import { TermViewingModal } from '../TermViewingModal';
import { DeleteModal } from '../DeleteModal';

interface ICardsViewProps {
  loading: boolean;
  termsBySupplier: Term[];
  onDelete(termId: string): void;
}

export const CardsView: FC<ICardsViewProps> = (props) => {
  const { loading, termsBySupplier, onDelete } = props;
  const [chosenTerm, setChosenTerm] = useState<Term | null>(null);
  // Delete State
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [termToDelete, setTermToDelete] = useState<string>('');
  const [deletedTermName, setDeletedTermName] = useState<string>('');

  // View State
  const [showViewingModal, setShowViewingModal] = useState(false);

  const handleOnClose = () => {
    setShowViewingModal(false);
  };

  return (
    <>
      <Title order={4} mb="lg">
        Terms created
      </Title>
      <Stack gap="sm">
        {loading ? (
          <LoadingCards />
        ) : termsBySupplier?.length ? (
          termsBySupplier?.map((term: Term) => (
            <TermCard
              key={term.id}
              term={term}
              isNewlyCreated={isNewlyCreated(term.createdAt)}
              onDeleteClick={() => {
                setTermToDelete(term?.id);
                setShowDeleteModal(true);
                setDeletedTermName(term?.name);
              }}
              setChosenTerm={setChosenTerm}
              setShowViewingModal={setShowViewingModal}
            />
          ))
        ) : (
          <Center
            style={{
              height: '50vh',
            }}
          >
            <Stack>
              <Image src="/cubes.png" alt="Stacked cubes" width={140} height={78} />
              <Text ta="center">
                You’ve not created any terms yet.
                <br />
                Once you do, they will appear here.
              </Text>
            </Stack>
          </Center>
        )}
      </Stack>
      <DeleteModal
        open={showDeleteModal && !!termToDelete}
        onDelete={() => {
          setTermToDelete('');
          setShowDeleteModal(false);
          onDelete(termToDelete);
        }}
        onCancel={() => {
          setTermToDelete('');
          setShowDeleteModal(false);
        }}
        termName={deletedTermName}
      />
      {chosenTerm && (
        <TermViewingModal
          showViewingModal={showViewingModal}
          onClose={handleOnClose}
          chosenTerm={chosenTerm}
        />
      )}
    </>
  );
};
