import { gql } from '@apollo/client';

const DeleteActivityAttendeeNote = gql`
  mutation deleteActivityAttendeeNote($id: UUID!) {
    deleteActivityAttendeeNote(id: $id) {
      success
    }
  }
`;

export default DeleteActivityAttendeeNote;
