import { FC, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { Box, Title, Group, Button, Overlay, Text, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { TermCreateInput } from 'types';
import classes from '../../TermDates.module.scss';
import { LabelWithPopover } from '../../../../';
import { OffDatesPopoverContent, StartAndEndPopoverContent } from './PopoverContent';
import { DateInput, DatePicker } from '@mantine/dates';
import { LeavePageModal } from '../../../../LeavePageModal';
import { PebbleTextInput } from 'components/common';
import classNames from 'classnames';
import { Actions, trackAction } from 'utils/amplitude';

dayjs.extend(isBetween);

interface ICreateTermForm {
  onAddTerm(term: TermCreateInput): void;
  supplierId: string;
  loading?: boolean;
}

export const CreateTermForm: FC<ICreateTermForm> = ({ onAddTerm, supplierId, loading }) => {
  const theme = useMantineTheme();
  const [termName, setTermName] = useState('');
  const [start, setStart] = useState<Date | null>(null);
  const [end, setEnd] = useState<Date | null>(null);
  const [offDates, setOffDates] = useState<Date[]>([]);
  const [month, setMonth] = useState(new Date());
  const [error, setError] = useState('');

  const isMobile = useMediaQuery('(max-width: 768px)', true);

  useEffect(() => {
    setOffDates([]);
    if (start && end) {
      if (start >= end) {
        setError('Start date cannot be later than end date.');
        return;
      }
    }
    setError('');
  }, [start, end]);

  const formValid = [termName, start, end].every(Boolean);

  const formPartiallyComplete = [termName, start, end].some(Boolean);

  const formatDate = (date: Date | null) => {
    if (!date) {
      return '';
    }
    return dayjs(date).format('YYYY-MM-DD');
  };

  const getDayClass = (date: Date) => {
    const dayjsDate = dayjs(date);
    const dayjsStart = dayjs(start);
    const dayjsEnd = dayjs(end);

    if (dayjsDate.isSame(dayjsStart, 'day') || dayjsDate.isSame(dayjsEnd, 'day')) {
      return classes.boundaryDay;
    }
    if (dayjsDate.isBetween(dayjsStart, dayjsEnd)) {
      return classes.betweenDay;
    }
    return '';
  };

  const getExcludedDays = (date: Date): boolean => {
    const dayjsDate = dayjs(date);
    const dayjsStart = dayjs(start);
    const dayjsEnd = dayjs(end);
    return !dayjsDate.isBetween(dayjsStart, dayjsEnd, 'day', '[]');
  };

  const reset = () => {
    setTermName('');
    setStart(null);
    setEnd(null);
    setOffDates([]);
  };

  const handleSubmit = () => {
    const startDate = formatDate(start);
    const endDate = formatDate(end);
    const offDatesAsStrings = offDates.map(formatDate);

    onAddTerm({
      startDate,
      endDate,
      name: termName,
      supplier: supplierId,
      ...(offDatesAsStrings.length > 0 && {
        offDates: offDatesAsStrings,
      }),
    });
    reset();
  };

  const minMaxProps = {
    ...(start && {
      minDate: start,
    }),
    ...(end && {
      maxDate: end,
    }),
  };

  const noDates = !start || !end;

  return (
    <Box className={classes.formWrapper}>
      <Box className={classes.sectionWrapper}>
        <Title order={4} mb="lg">
          Create a term
        </Title>
        <PebbleTextInput
          label="Name your term"
          required
          placeholder="Eg. Spring Term"
          value={termName}
          onChange={(e) => setTermName(e.currentTarget.value)}
          mb="lg"
          action={Actions.TERM_NAME}
        />
        <LabelWithPopover
          labelText="Set the start & end dates of your term"
          popoverContent={<StartAndEndPopoverContent />}
        />
        <Group justify="space-between" mb="lg" mt="sm" wrap="nowrap">
          <DateInput
            classNames={{
              day: classes.dayDateInput,
              input: classes.termDateInput,
            }}
            radius={4}
            placeholder="dd-mm-yyyy"
            onChange={(date) => {
              setStart(date);
              if (date) {
                setMonth(date);
              }
            }}
            onBlur={() => trackAction(Actions.TERM_START)}
            value={start}
            valueFormat="DD-MM-YYYY"
            maxLevel="month"
            aria-label="start date"
          />
          to
          <DateInput
            classNames={{
              day: classes.dayDateInput,
              input: classes.termDateInput,
            }}
            radius={4}
            placeholder="dd-mm-yyyy"
            onChange={setEnd}
            onBlur={() => trackAction(Actions.TERM_END)}
            value={end}
            valueFormat="DD-MM-YYYY"
            maxLevel="month"
            aria-label="end date"
          />
        </Group>
        {error && (
          <Text color="red" aria-errormessage={error}>
            {error}
          </Text>
        )}
        <Box className={classes.disabledSectionWrapper}>
          {(noDates || error) && (
            <Overlay
              opacity={0.5}
              color={theme.colors.gray[2]}
              radius="xs"
              zIndex={5}
              data-testid="overlay"
            />
          )}
          <LabelWithPopover
            labelText="Now, select any Off Days within the term time (e.g. holidays, inset days, half term)."
            popoverContent={<OffDatesPopoverContent />}
          />
          <Box className={classes.calendarWrapper}>
            <div data-testid="test-id-calendar">
              <DatePicker
                classNames={{
                  day: classNames(classes.day, { [classes.weekendWhite]: start && end }),
                }}
                type="multiple"
                date={month}
                onDateChange={setMonth}
                value={offDates}
                onChange={(e) => {
                  setOffDates(e);
                  trackAction(Actions.TERM_CALENDAR);
                }}
                getDayProps={(date: Date) => ({ className: getDayClass(date) })}
                excludeDate={getExcludedDays}
                maxLevel="month"
                hideOutsideDates
                withCellSpacing={false}
                aria-disabled={noDates}
                mb="lg"
                size={isMobile ? 'sm' : 'md'}
                {...minMaxProps}
              />
            </div>
            <div className={classes.calendarKey}>
              <div className={classes.flexKey}>
                <div className={classes.termDateKey} />
                <Text size="sm" fw={600}>
                  Term dates
                </Text>
              </div>
              <div className={classes.flexKey}>
                <div className={classes.offDaysKey} />
                <Text size="sm" fw={600}>
                  Off days
                </Text>
              </div>
            </div>
          </Box>
          <Group wrap="nowrap">
            <Button
              fullWidth
              className={classes.pinkButton}
              size="md"
              radius="lg"
              type="submit"
              onClick={() => {
                handleSubmit();
                trackAction(Actions.ADD_TERM);
              }}
              disabled={!formValid}
              loading={loading}
            >
              Add term
            </Button>
            <Button
              fullWidth
              className={classes.outlinePinkButton}
              variant="outline"
              size="md"
              radius="lg"
              type="reset"
              onClick={() => {
                reset();
                trackAction(Actions.CANCEL_TERM);
              }}
              disabled={!formValid}
            >
              Cancel
            </Button>
          </Group>
        </Box>
        <LeavePageModal messageText={'term details'} formIsDirty={formPartiallyComplete} />
      </Box>
    </Box>
  );
};
