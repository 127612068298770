import { Button, ButtonProps } from '@mantine/core';
import classes from './PebbleButton.module.scss';
import { MouseEventHandler } from 'react';

interface PebbleButtonProps extends ButtonProps {
  children?: React.ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  type?: 'button' | 'submit' | 'reset';
}

const PebbleButton: React.FC<PebbleButtonProps> = ({ children, onClick, type, ...props }) => {
  return (
    <Button
      onClick={onClick}
      color="pink"
      classNames={{
        root: props.variant === 'outline' ? classes.pinkOutlineButton : classes.pinkButton,
        ...props.classNames,
      }}
      type={type || 'button'}
      {...props}
    >
      {children}
    </Button>
  );
};

export default PebbleButton;
