import { gql } from '@apollo/client';

export const SupplierCustomersFromBookings = gql`
  query supplierCustomersFromBookings($supplierId: UUID!, $activityIds: [UUID]!) {
    supplierCustomersFromBookings(supplierId: $supplierId, activityIds: $activityIds) {
      customerId
      customerFullName
      customerEmail
      children {
        id
        fullName
      }
    }
  }
`;
