import { gql } from '@apollo/client';

const activityCreate = gql`
  mutation activityCreate($input: MarketplaceActivityCreateInput) {
    activityCreate(input: $input) {
      activity {
        id
      }
    }
  }
`;

export default activityCreate;
