import { gql } from '@apollo/client';

const membershipCreate = gql`
  mutation supplierMembershipCreate(
    $supplierId: UUID!
    $adminAccess: [SupplierAdminAccessEnum]
    $supplierAdminFullName: String!
    $supplierAdminEmail: String!
  ) {
    supplierMembershipCreate(
      supplierId: $supplierId
      adminAccess: $adminAccess
      supplierAdminFullName: $supplierAdminFullName
      supplierAdminEmail: $supplierAdminEmail
    ) {
      supplierAdminMembership {
        id
      }
    }
  }
`;

export default membershipCreate;
