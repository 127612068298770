import { gql } from '@apollo/client';

const SupplierFutureActivities = gql`
  query SupplierFutureActivities(
    $supplierId: UUID!
    $searchTerms: [String]
    $activityId: UUID
    $activityTypes: [ActivityTypeEnum]
  ) {
    supplierFutureActivities(
      supplierId: $supplierId
      searchTerms: $searchTerms
      activityId: $activityId
      activityTypes: $activityTypes
    ) {
      activity {
        id
        name
        location {
          addressLine1
          addressLine2
          city
          postCode
        }
        ageRange
        supplier {
          id
        }
        activityType
        blockSubtype
      }
      dateRange
      classes {
        name
        id
        dateRange
        sessions {
          date
          products {
            dateRange
            weekdays
            startTime
            endTime
            timeGroup
            spotsLeft
            numberOfSessionsAvailable
            checkoutKey
            checkoutValue
          }
        }
        weekdays
        blocks {
          dateRange
          weekdays
          startTime
          endTime
          timeGroup
          spotsLeft
          numberOfSessionsAvailable
          checkoutKey
          checkoutValue
          allBlockDates {
            date
            spotsLeft
            endTime
            startTime
            isInPast
          }
        }
      }
    }
  }
`;

export default SupplierFutureActivities;
