import { FC } from 'react';
import { Box, Modal, Text, Group, Button, useMantineTheme } from '@mantine/core';
import { useMutation } from '@apollo/client';
import { deleteActivityMutation } from 'components/pages/ScheduledActivities/graphql/mutation';
import classes from './DeleteModal.module.scss';
import { showErrorMessage } from 'utils/showErrorMessage/showErrorMessage';
interface IDeleteModalProps {
  open: boolean;
  onClose(): void;
  onCancel(): void;
  activityId: string | null;
  token: string;
  afterDelete(): void;
}

export const DeleteModal: FC<IDeleteModalProps> = ({
  open,
  onClose,
  onCancel,
  activityId,
  token,
  afterDelete,
}) => {
  const theme = useMantineTheme();
  const [deleteActivity, { loading }] = useMutation(deleteActivityMutation, {
    onCompleted: (data) => {
      if (data.activityDelete) {
        onClose();
      }
    },
    onError: (error) => {
      showErrorMessage(error);
    },
  });

  const handleDelete = () => {
    if (activityId && token) {
      deleteActivity({
        variables: {
          id: activityId,
        },
        context: {
          headers: {
            Authorization: `${token}`,
          },
        },
      });

      afterDelete();
    }
  };

  return (
    <Modal opened={open} onClose={onCancel} size={640} withCloseButton={false} centered>
      <Box
        style={{
          textAlign: 'center',
          padding: '24px',
        }}
      >
        <Text fw={800} size="xl" color={theme.colors.blue[5]} mt={24}>
          Are you sure you want to delete this Activity?
        </Text>
        <Group justify="center" mt={24}>
          <Button
            mb="md"
            size="md"
            radius="xl"
            variant="outline"
            className={classes.deleteButton}
            loading={loading}
            disabled={loading}
            onClick={handleDelete}
          >
            Delete
          </Button>
          <Button
            mb="md"
            size="md"
            radius="xl"
            onClick={onCancel}
            disabled={loading}
            className={classes.cancelButton}
          >
            Cancel
          </Button>
        </Group>
      </Box>
    </Modal>
  );
};
