export const PebbleColour = ({ size = 150, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 390 141" width={size} {...props}>
    <path
      d="M7.99294 64.8256C7.99294 64.8256 12.5797 60.7984 23.1033 60.6332C33.6269 60.468 45.1578 62.1697 49.3029 66.5934C53.4479 71.017 50.8387 74.7468 50.8387 74.7468C50.8387 74.7468 48.8941 78.08 45.1248 78.9474C41.3555 79.8148 37.0205 80.7648 31.0879 80.8019C25.1552 80.8391 12.6375 80.1576 9.80122 77.7C6.96494 75.2424 5.06995 73.2227 5.08234 70.4718C5.09472 67.721 7.99294 64.8256 7.99294 64.8256Z"
      fill="#FA7BBF"
    />
    <path
      d="M15.9609 42.0878C18.9482 40.1479 22.4403 39.1309 26.0015 39.1635C31.8557 39.2667 33.759 40.4315 33.759 40.4315C33.759 40.4315 37.6976 42.8808 38.7586 43.1948C39.8196 43.5087 41.6857 44.1571 42.3422 46.0612C42.9986 47.9654 43.9275 50.109 42.4578 51.3812C40.988 52.6534 40.5215 53.339 37.2063 54.2229C34.4222 54.915 31.5466 55.1645 28.685 54.9622C26.468 54.859 21.2331 54.322 19.4124 54.3179C17.5917 54.3138 14.2063 52.3518 13.7852 50.0429C13.3641 47.7341 13.4384 43.1782 15.9609 42.0878Z"
      fill="#ABEE56"
    />
    <path
      d="M1.66805 94.9857C1.66805 94.9857 3.73231 90.6075 11.2503 88.088C17.3173 86.0972 23.7885 85.671 30.064 86.8489C34.6053 87.6543 41.372 88.6662 48.0189 91.0288C54.6658 93.3914 56.4617 94.8205 56.4163 98.0505C56.3709 101.28 53.0846 103.833 52.0731 104.246C48.3042 106.019 44.2026 106.974 40.0385 107.046C33.1769 107.253 17.6908 107.46 15.0568 107.261C12.4228 107.063 5.9741 106.848 3.39378 103.432C0.813454 100.017 0.404728 97.0509 1.66805 94.9857Z"
      fill="#548CED"
    />
    <path
      d="M86.4141 42.4348C86.4141 41.5585 86.762 40.718 87.3814 40.0983C88.0008 39.4786 88.8409 39.1305 89.7169 39.1305H114.294C130.593 39.1305 141.15 47.5606 141.15 61.8477V62.0335C141.15 78.4808 129.388 86.2996 113.365 86.2996H104.381V103.99C104.381 104.866 104.033 105.707 103.414 106.327C102.795 106.946 101.955 107.294 101.079 107.294H89.7169C88.8409 107.294 88.0008 106.946 87.3814 106.327C86.762 105.707 86.4141 104.866 86.4141 103.99V42.4348ZM112.998 72.1984C119.294 72.1984 123.278 68.7702 123.278 63.6733V62.6655C123.278 57.106 119.294 55.0573 112.903 55.0573H107.684C106.808 55.0573 105.968 55.4054 105.349 56.0251C104.729 56.6448 104.381 57.4853 104.381 58.3616V68.8941C104.381 69.7705 104.729 70.611 105.349 71.2306C105.968 71.8503 106.808 72.1984 107.684 72.1984H112.998Z"
      fill="#548CED"
    />
    <path
      d="M142.995 81.6361V81.4503C142.995 66.808 153.461 55.2264 168.278 55.2264C185.507 55.2264 193.462 67.9232 193.462 82.6522V82.962C193.462 83.8383 193.114 84.6788 192.495 85.2985C191.876 85.9182 191.036 86.2663 190.16 86.2663H159.852C161.239 91.7349 165.219 94.5271 170.69 94.5271C174.145 94.5271 176.936 93.4862 179.772 91.2228C180.365 90.7424 181.107 90.4823 181.87 90.4869C182.633 90.4914 183.371 90.7602 183.959 91.2476L188.867 95.3201C189.224 95.6152 189.515 95.9821 189.721 96.3968C189.927 96.8115 190.043 97.2649 190.062 97.7276C190.082 98.1903 190.004 98.6519 189.834 99.0825C189.663 99.513 189.404 99.903 189.074 100.227C184.433 104.799 178.187 107.695 169.835 107.695C154.109 107.67 142.995 97.1086 142.995 81.6361ZM175.091 77.2786C175.342 77.2784 175.591 77.2205 175.817 77.1094C176.043 76.9983 176.241 76.837 176.395 76.6377C176.549 76.4383 176.656 76.2064 176.707 75.9595C176.758 75.7126 176.751 75.4574 176.688 75.2134C175.536 70.8641 172.469 68.1917 168.278 68.1917C164.27 68.1917 161.367 70.8351 160.063 75.1514C159.99 75.3979 159.976 75.6579 160.022 75.9108C160.067 76.1637 160.171 76.4025 160.325 76.6082C160.479 76.8139 160.678 76.9809 160.908 77.0958C161.138 77.2107 161.391 77.2705 161.648 77.2703L175.091 77.2786Z"
      fill="#548CED"
    />
    <path
      d="M215.5 100.351V103.99C215.5 104.867 215.152 105.707 214.533 106.327C213.914 106.946 213.074 107.294 212.198 107.294H201.216C200.34 107.294 199.5 106.946 198.88 106.327C198.261 105.707 197.913 104.867 197.913 103.99V42.1334C197.913 41.257 198.261 40.4165 198.88 39.7969C199.5 39.1772 200.34 38.829 201.216 38.829H212.198C213.074 38.829 213.914 39.1772 214.533 39.7969C215.152 40.4165 215.5 41.257 215.5 42.1334V63.0125C218.927 58.8408 223.184 55.2267 230.689 55.2267C242.633 55.2267 252.913 65.1397 252.913 81.1697V81.3556C252.913 97.6624 242.633 107.579 230.78 107.579C223.196 107.579 218.754 104.151 215.5 100.351ZM235.689 81.4506V81.2647C235.689 74.5941 231.057 69.869 225.409 69.869C219.666 69.869 215.129 74.5941 215.129 81.2647V81.4506C215.129 88.1212 219.67 92.8464 225.409 92.8464C231.069 92.8464 235.701 88.2121 235.701 81.4506H235.689Z"
      fill="#548CED"
    />
    <path
      d="M274.951 100.351V103.99C274.951 104.867 274.603 105.707 273.984 106.327C273.364 106.946 272.524 107.294 271.648 107.294H260.675C259.799 107.294 258.958 106.946 258.339 106.327C257.72 105.707 257.372 104.867 257.372 103.99V42.1334C257.372 41.257 257.72 40.4165 258.339 39.7969C258.958 39.1772 259.799 38.829 260.675 38.829H271.648C272.524 38.829 273.364 39.1772 273.984 39.7969C274.603 40.4165 274.951 41.257 274.951 42.1334V63.0125C278.378 58.8408 282.638 55.2267 290.14 55.2267C302.088 55.2267 312.368 65.1397 312.368 81.1697V81.3556C312.368 97.6624 302.088 107.579 290.231 107.579C282.655 107.579 278.208 104.151 274.951 100.351ZM295.139 81.4506V81.2647C295.139 74.5941 290.511 69.869 284.859 69.869C279.117 69.869 274.579 74.5941 274.579 81.2647V81.4506C274.579 88.1212 279.121 92.8464 284.859 92.8464C290.528 92.8464 295.156 88.2121 295.156 81.4506H295.139Z"
      fill="#548CED"
    />
    <path
      d="M320.179 38.829H331.099C331.975 38.829 332.815 39.1772 333.434 39.7969C334.054 40.4165 334.402 41.257 334.402 42.1334V103.99C334.402 104.867 334.054 105.707 333.434 106.327C332.815 106.946 331.975 107.294 331.099 107.294H320.199C319.323 107.294 318.483 106.946 317.864 106.327C317.245 105.707 316.897 104.867 316.897 103.99V42.1334C316.897 41.2606 317.242 40.4232 317.857 39.8041C318.472 39.185 319.306 38.8345 320.179 38.829Z"
      fill="#548CED"
    />
    <path
      d="M339.191 81.6361V81.4503C339.191 66.808 349.66 55.2264 364.478 55.2264C381.702 55.2264 389.662 67.9232 389.662 82.6522V82.962C389.662 83.8383 389.314 84.6788 388.694 85.2985C388.075 85.9182 387.235 86.2663 386.359 86.2663H356.043C357.434 91.7349 361.41 94.5271 366.881 94.5271C370.34 94.5271 373.127 93.4862 375.988 91.2228C376.581 90.7434 377.321 90.484 378.084 90.4885C378.846 90.4931 379.583 90.7612 380.17 91.2476L385.083 95.3201C385.44 95.6152 385.731 95.9821 385.936 96.3968C386.142 96.8115 386.259 97.2649 386.278 97.7276C386.298 98.1903 386.22 98.6519 386.049 99.0825C385.879 99.513 385.62 99.903 385.29 100.227C380.649 104.799 374.399 107.695 366.047 107.695C350.305 107.67 339.191 97.1086 339.191 81.6361ZM371.698 77.2786C371.951 77.279 372.2 77.2216 372.426 77.1108C372.653 77 372.851 76.8387 373.006 76.6393C373.161 76.4399 373.268 76.2077 373.319 75.9605C373.37 75.7133 373.363 75.4577 373.3 75.2134C372.144 70.8641 369.077 68.1917 364.891 68.1917C360.878 68.1917 357.979 70.8351 356.671 75.1514C356.598 75.3982 356.584 75.6586 356.63 75.9118C356.675 76.165 356.78 76.4041 356.934 76.6099C357.088 76.8157 357.289 76.9825 357.519 77.0972C357.749 77.2119 358.003 77.2711 358.26 77.2703L371.698 77.2786Z"
      fill="#548CED"
    />
  </svg>
);
