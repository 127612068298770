import React from 'react';
import { Text, Tooltip, Group, Box, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import classes from './BookingStatus.module.scss';
import classNames from 'classnames';

interface IBookingsStatusProps {
  totalBookings: number;
  totalCapacity: number;
  description?: string;
  borderedDot?: boolean | string;
  withTooltip?: boolean;
  totalSpotsFilledRatio?: number;
}

export const GREEN = '#9DE551';
export const YELLOW = '#F7D347';
export const RED = '#E74522';

export const BookingStatus: React.FC<IBookingsStatusProps> = ({
  totalBookings,
  totalCapacity,
  description,
  borderedDot,
  withTooltip = true,
  totalSpotsFilledRatio,
}) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);

  const getBookingsPercentage = () => {
    if (totalSpotsFilledRatio) {
      return totalSpotsFilledRatio * 100;
    } else if (totalCapacity) {
      return (100 * totalBookings) / totalCapacity;
    } else {
      return 0;
    }
  };

  // Determine the color based on the bookingsPercentage
  const getColor = (): string => {
    if (getBookingsPercentage() < 25) return RED;
    if (getBookingsPercentage() <= 75) return YELLOW;
    return GREEN;
  };

  // Determine the border styles based on the borderedDot prop
  const getBorderStyles = (): string => {
    if (!borderedDot) return 'none';
    if (borderedDot === 'white') return '2px solid white';
    if (getBookingsPercentage() < 25) return '2px solid #971B00';
    if (getBookingsPercentage() <= 75) return '2px solid #EFC830';
    return '2px solid #7ECB01';
  };

  const renderBookingStatusDot = () => {
    return (
      <Group gap={isMobile ? '4px' : 'xs'}>
        <Box
          className={classNames(classes.dot, { [classes.borderedDot]: borderedDot })}
          style={{ backgroundColor: getColor(), border: getBorderStyles() }}
          data-testid="booking-status-dot"
        />
        <Text size={withTooltip ? 'sm' : 'md'} fw={700} c={theme.colors.blue[8]}>
          {getBookingsPercentage().toFixed(0)}%
        </Text>
      </Group>
    );
  };

  return (
    <>
      {withTooltip ? (
        <Tooltip
          classNames={{ tooltip: classes.tooltip }}
          label={`${totalBookings}/${totalCapacity} ${description}`}
        >
          {renderBookingStatusDot()}
        </Tooltip>
      ) : (
        renderBookingStatusDot()
      )}
    </>
  );
};
