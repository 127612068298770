import { Badge } from '@mantine/core';
import classes from './NewBadge.module.scss';

export const NewBadge: React.FC = () => {
  return (
    <Badge
      classNames={{
        root: classes.badge,
        label: classes.badgeLabel,
      }}
    >
      New!
    </Badge>
  );
};
