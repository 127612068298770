import { Drawer, Modal, SegmentedControl, Text, useMantineTheme } from '@mantine/core';
import { SortingRule } from '../CustomerSummary';
import classes from './SortSettings.module.scss';
import { X } from '@phosphor-icons/react';

interface ISortSettingsProps {
  showSort: boolean;
  setShowSort(val: boolean): void;
  sortingRule: SortingRule;
  setSortingRule(val: SortingRule): void;
  isMobile?: boolean;
}

const SortSettings: React.FC<ISortSettingsProps> = ({
  showSort,
  setShowSort,
  sortingRule,
  setSortingRule,
  isMobile,
}: ISortSettingsProps) => {
  const theme = useMantineTheme();

  const sortSwitch = () => {
    return (
      <>
        <Text fw={600} mb={6}>
          Alphabetical
        </Text>
        <SegmentedControl
          value={sortingRule}
          onChange={(value) => {
            setSortingRule(value as SortingRule);
            setShowSort(false);
          }}
          size="lg"
          radius="lg"
          transitionDuration={0}
          classNames={{
            root: classes.segmentedControlRoot,
            label: classes.pseudoTabButtonLabel,
            indicator: classes.controlIndicator,
          }}
          data={[
            { label: 'A - Z', value: SortingRule.A_TO_Z },
            { label: 'Z - A', value: SortingRule.Z_TO_A },
          ]}
        />
      </>
    );
  };
  return (
    <>
      {isMobile ? (
        <Drawer
          opened={showSort}
          onClose={() => setShowSort(false)}
          position="bottom"
          size="25%"
          title={'Sort by'}
          classNames={{
            content: classes.drawerContent,
            header: classes.header,
            body: classes.drawerBody,
          }}
          closeButtonProps={{
            icon: <X weight="bold" size={22} color={theme.colors.gray[6]} />,
          }}
        >
          {sortSwitch()}
        </Drawer>
      ) : (
        <Modal
          opened={showSort}
          onClose={() => {
            setShowSort(false);
          }}
          withCloseButton={true}
          centered
          size={375}
          title={'Sort by'}
          closeButtonProps={{
            icon: <X weight="bold" size={22} color={theme.colors.gray[6]} />,
          }}
          classNames={{
            header: classes.header,
          }}
        >
          {sortSwitch()}
        </Modal>
      )}
    </>
  );
};

export default SortSettings;
