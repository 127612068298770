import { gql } from '@apollo/client';

const membershipUpdate = gql`
  mutation supplierMembershipUpdate(
    $supplierAdminId: UUID!
    $supplierId: UUID!
    $adminAccess: [SupplierAdminAccessEnum]
  ) {
    supplierMembershipUpdate(
      supplierAdminId: $supplierAdminId
      supplierId: $supplierId
      adminAccess: $adminAccess
    ) {
      supplierAdminMembership {
        id
        isSuperadmin
        adminAccess {
          id
          name
          niceName
        }
        supplierAdmin {
          id
          emailAddress
          fullName
          cognitoStatus
        }
      }
    }
  }
`;

export default membershipUpdate;
