import classes from './TimeRange.module.scss';
import { ActionIcon, Group, Stack, Text, useMantineTheme } from '@mantine/core';
import { TimeInput, TimeInputProps } from '@mantine/dates';
import { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import classNames from 'classnames';
import { Clock } from '@phosphor-icons/react';

interface ITimeRangeProps extends Omit<TimeInputProps, 'value'> {
  onRangeApplied: (val: [string | undefined, string | undefined]) => void;
  value: [string | undefined, string | undefined];
  pseudoDisabled?: boolean;
}

export const TimeRange: React.FC<ITimeRangeProps> = ({
  onRangeApplied,
  value,
  error,
  label,
  disabled,
  pseudoDisabled,
}) => {
  const theme = useMantineTheme();
  const [startValue, endValue] = value;
  const startRef = useRef<HTMLInputElement>(null);
  const endRef = useRef<HTMLInputElement>(null);

  const [start, setStart] = useState(startValue);
  const [end, setEnd] = useState(endValue);

  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);

  useEffect(() => {
    if ((start && start !== startValue) || (end && end !== endValue)) {
      setTimeout(() => {
        onRangeApplied([start, end]);
      }, 500);
    }
  }, [start, end, startValue, endValue, onRangeApplied]);

  return (
    <Stack gap="xs">
      {label && <Text className={classes.label}>{label}</Text>}
      <Group
        className={classNames(classes.wrapper, {
          [classes.wrapperError]: error,
          [classes.wrapperPseudoDisabled]: pseudoDisabled,
        })}
        gap={0}
      >
        <TimeInput
          ref={startRef}
          value={start}
          error={error}
          disabled={disabled}
          data-testid="start-time"
          aria-label="Start time"
          classNames={{
            root: classNames({
              [classes.disabledRoot]: disabled,
            }),
            input: disabled ? classes.disabledInput : classes.input,
          }}
          rightSection={
            <ActionIcon
              variant="subtle"
              onClick={() => {
                if (isMobile) {
                  startRef.current?.focus();
                } else {
                  // eslint-disable-next-line
                  // @ts-ignore
                  if (startRef.current?.showPicker) {
                    startRef.current?.showPicker();
                  }
                }
              }}
            >
              <Clock color={error ? theme.colors.red[6] : theme.colors.blue[8]} size={16} />
            </ActionIcon>
          }
          onChange={(e) => {
            setStart(e.target.value);
          }}
        />
        -
        <TimeInput
          ref={endRef}
          value={end}
          disabled={disabled}
          aria-label="End time"
          data-testid="end-time"
          error={error}
          classNames={{
            root: classNames({
              [classes.disabledRoot]: disabled,
            }),
            input: disabled ? classes.disabledInput : classes.input,
          }}
          rightSection={
            <ActionIcon
              variant="subtle"
              onClick={() => {
                if (isMobile) {
                  endRef.current?.focus();
                } else {
                  // eslint-disable-next-line
                  // @ts-ignore
                  if (endRef.current?.showPicker) {
                    endRef.current?.showPicker();
                  }
                }
              }}
            >
              <Clock color={error ? theme.colors.red[6] : theme.colors.blue[8]} size={16} />
            </ActionIcon>
          }
          onChange={(e) => {
            setEnd(e.target.value);
          }}
        />
      </Group>
    </Stack>
  );
};
