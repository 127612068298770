import { ReactNode } from 'react';
import { PebbleLogo } from '@ui';
import { NextPage } from 'next';
import Image from 'next/legacy/image';
import Link from 'next/link';
import classes from './PublicPage.module.scss';

interface PublicPageProps {
  children: ReactNode;
}

export const PublicPage: NextPage<PublicPageProps> = ({ children }) => {
  return (
    <div className={classes.container}>
      <header className={classes.backLink}>
        <Link href="/" passHref legacyBehavior>
          <PebbleLogo variant="default" />
        </Link>
      </header>
      <main role="main" className={classes.content}>
        <div className={classes.contentWrapper}>{children}</div>
      </main>
      <aside className={classes.illustration}>
        <Image
          src="/defaultIllustration.svg"
          alt="Laptop with Pebble home screen displayed on screen"
          width={580}
          height={838}
        />
        {/* <img src={ill} alt="Laptop with Pebble home screen displayed on screen" /> */}
      </aside>
    </div>
  );
};
