import { ReactNode } from 'react';
import { Flex, Group, Title, useMantineTheme } from '@mantine/core';
import classes from './PageHeader.module.scss';
import { useMediaQuery } from '@mantine/hooks';
import classNames from 'classnames';

interface IPageHeaderProps {
  title: string;
  titleTag?: JSX.Element;
  intro?: JSX.Element;
  rightComponent?: ReactNode;
  topComponent?: ReactNode;
}

export const PageHeader: React.FC<IPageHeaderProps> = ({
  title,
  titleTag,
  intro,
  rightComponent,
  topComponent,
}) => {
  const theme = useMantineTheme();

  const isBookingsPage = title === 'Bookings';
  const isIntegrationsPage = title === 'Integrations';

  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  return (
    <>
      {!isMobile && (
        <div
          className={classNames(classes.header, {
            [classes.bookingsHeader]: isBookingsPage,
            [classes.integrationsHeader]: isIntegrationsPage,
          })}
        >
          <div className={classes.headerWrapper}>
            <div className={classes.pageIntro}>
              {topComponent && <Group className={classes.topComponent}>{topComponent}</Group>}
              {title && !titleTag && (
                <Title order={1} className={classes.title}>
                  {title}
                </Title>
              )}
              {title && titleTag && (
                <Flex align={'center'} gap={8}>
                  <Title order={1} className={classes.title}>
                    {title}
                  </Title>
                  {titleTag}
                </Flex>
              )}
              {intro && <div className={classes.introText}>{intro}</div>}
            </div>
            {rightComponent && (
              <Group
                style={{
                  marginLeft: 'auto',
                  minWidth: '300px',
                }}
                className={classes.rightComponent}
                wrap="nowrap"
              >
                {rightComponent}
              </Group>
            )}
          </div>
        </div>
      )}
      {isMobile && (
        <>
          <div className={classes.stickyHeader}>
            {topComponent && <Group>{topComponent}</Group>}
            {title && <h1 className={classes.title}>{title}</h1>}
          </div>
          <div className={classes.hiddenDescription}>
            {' '}
            {intro && <div className={classes.introText}>{intro}</div>}
          </div>
        </>
      )}
    </>
  );
};
