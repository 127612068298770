import { FC } from 'react';
import { Text } from '@mantine/core';

export const StartAndEndPopoverContent: FC = () => (
  <>
    <Text size="sm">
      This is first and last dates of the term. Any activities using this new term will run with in
      those dates.
    </Text>
  </>
);
