import { gql } from '@apollo/client';

const CreateDiscount = gql`
  mutation promotionCreateMutation($input: PromotionCreateInput) {
    promotionCreate(input: $input) {
      promotion {
        id
        status
        name
        code
        discountType
        discountAmount
        allowMultiple
        bookingType
        validFrom
        validTo
        maxRedemptions
        pebblePaysForPromotions
        maxDiscountAmount
        firstBookingOnlyPerSupplier
        minSessionsNumber
        minAttendeesNumber
        ticketsType
        hasBookings
        isExpired
      }
    }
  }
`;

export default CreateDiscount;
