import { gql } from '@apollo/client';

const GetClassesFromActivity = gql`
  query activitiesBySupplier($supplierId: UUID!, $activityId: UUID) {
    activitiesBySupplier(supplierId: $supplierId, activityId: $activityId) {
      activities {
        id
        slug
        status
        createdAt
        location {
          addressLine1
          addressLine2
          city
          country
          postCode
        }
        locationName
        locationAdditionalInfo
        name
        bookingType
        activityType
        blockSubtype
        minDate
        maxDate
        weekdays
        sessions {
          id
        }
        classes {
          id
          name
          term {
            id
            name
            offDates
          }
          hasBookings
          totalBookings
          totalCapacity
          totalSpotsFilledRatio
          blocks {
            id
            minDate
            maxDate
            coreStartTime
            coreEndTime
            totalSpotsFilledRatio
            sessions {
              id
              date
              dayOfTheWeek
              capacity
              startTime
              endTime
            }
          }
          sessions {
            id
            date
            dayOfTheWeek
            capacity
            bookingCount
            startTime
            endTime
            addons {
              id
            }
          }
          tickets {
            id
          }
          addons {
            id
          }
        }
        schedules {
          id
          startTime
          endTime
          dayOfTheWeek
        }
        times {
          startTime
          endTime
        }
        categories {
          id
          name
          slug
        }
        hasBookings
        isOnline
        bookingsInformation {
          totalBookings
          totalCapacity
          description
        }
        waitlistRequestCount
        enableRegisterCheckinCheckoutMode
      }
    }
  }
`;

export default GetClassesFromActivity;
