import { gql } from '@apollo/client';

const MoveAttendeeSubscription = gql`
  mutation moveAttendeeSubscription($input: MoveAttendeeSubInputType!) {
    moveAttendeeSub(input: $input) {
      success
    }
  }
`;

export default MoveAttendeeSubscription;
