import { FC } from 'react';
import { Box, Group, Text } from '@mantine/core';
import classes from './InfoBox.module.scss';
import { Info } from '@phosphor-icons/react';

interface IInfoBox {
  message: string;
}

export const InfoBox: FC<IInfoBox> = ({ message }) => {
  return (
    <Box className={classes.box}>
      <Group wrap="nowrap" gap={12} align="flex-start">
        <Info size={26} className={classes.icon} />
        <Text fw={600} size="sm">
          {message}
        </Text>
      </Group>
    </Box>
  );
};
