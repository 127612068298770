import { gql } from '@apollo/client';

const CategoriesQuery = gql`
  query {
    categories {
      id
      name
    }
  }
`;

export default CategoriesQuery;
