import { gql } from '@apollo/client';

const AddActivityQuery = gql`
  query addActivityQuery($supplierId: UUID!) {
    categories {
      id
      name
    }
    termsBySupplier(supplierId: $supplierId) {
      id
      isDeleted
      createdAt
      updatedAt
      name
      startDate
      endDate
      offDates
      supplier {
        id
      }
    }
    marketplaceSupplierMemberships(supplierId: $supplierId) {
      isSuperadmin
      adminAccess {
        id
        name
        niceName
      }
      supplierAdmin {
        id
        fullName
      }
      id
    }
  }
`;

export default AddActivityQuery;
