import { FC } from 'react';
import { AppShell, Burger, useMantineTheme } from '@mantine/core';
import { PebbleColour } from '@logos';

import classes from './Header.module.scss';

interface IHeaderProps {
  onBurgerClick(): void;
  sidebarOpen: boolean;
}

export const Header: FC<IHeaderProps> = ({ onBurgerClick, sidebarOpen = false }) => {
  const theme = useMantineTheme();

  return (
    <AppShell.Header p="md" className={classes.Header}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <PebbleColour size={120} />
        <Burger
          title="Open navigation"
          aria-label="Open navigation"
          opened={sidebarOpen}
          onClick={onBurgerClick}
          size="lg"
          color={theme.colors.pink[5]}
        />
      </div>
    </AppShell.Header>
  );
};
