import { FC } from 'react';
import { ComboBooking, BlockBooking, SingleSession, Subscriptions } from '@icons';
import { useMantineTheme } from '@mantine/core';
import { ActivityTypeEnum } from 'enums';

interface IActivityTypeIconProps {
  type?: ActivityTypeEnum;
}

export const ActivityTypeIcon: FC<IActivityTypeIconProps> = ({ type }) => {
  const theme = useMantineTheme();

  if (!type) return null;

  switch (type) {
    case ActivityTypeEnum.ONE_OFF_AND_BLOCK:
      return (
        <ComboBooking
          role="img"
          title="Block & Individual"
          size={20}
          color={theme.colors.blue[8]}
        />
      );
    case ActivityTypeEnum.BLOCK:
      return <BlockBooking role="img" title="Block" size={20} color={theme.colors.blue[8]} />;
    case ActivityTypeEnum.ONE_OFF:
      return <SingleSession role="img" title="Individual" size={20} color={theme.colors.blue[8]} />;
    case ActivityTypeEnum.SUBSCRIPTION:
      return (
        <Subscriptions role="img" title="Subscription" size={24} color={theme.colors.blue[8]} />
      );
  }
};
