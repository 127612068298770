import { gql } from '@apollo/client';

const DeleteWaitListMember = gql`
  mutation deleteWaitListMember($id: UUID!) {
    deleteWaitListMember(id: $id) {
      deleted
    }
  }
`;

export default DeleteWaitListMember;
