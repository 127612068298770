// import { Button, Flex } from '@mantine/core';
// import classNames from 'classnames';
// import classes from './ClassFilters.module.scss';
import { ClassesDateFilterEnum } from 'enums';
import PebbleSelect from 'components/common/PebbleSelect/PebbleSelect';
import { ActivityClass } from 'types';
import { Actions, trackAction } from 'utils/amplitude';

interface IClassFilters {
  classesDateFilter: ClassesDateFilterEnum;
  selectedClass: string | null;
  setSelectedClass: (val: string | null) => void;
  activityClasses: ActivityClass[];
  setClassesDateFilter: (val: ClassesDateFilterEnum) => void;
}

const ClassFilters: React.FC<IClassFilters> = ({
  // classesDateFilter,
  selectedClass,
  setSelectedClass,
  activityClasses,
  // setClassesDateFilter,
}) => {
  return (
    <>
      {/* // TODO put back in when BE accepts these parameters to the activitiesBySupplier query */}
      {/* <Flex gap="xs">
        <Button
          onClick={() => {
            setClassesDateFilter(ClassesDateFilterEnum.ALL);
            trackAction(Actions.AO_SCHEDULES_ALL);
          }}
          className={classNames(classes.toolbarButton, {
            [classes.active]: classesDateFilter === ClassesDateFilterEnum.ALL,
          })}
        >
          All
        </Button>
        <Button
          onClick={() => {
            setClassesDateFilter(ClassesDateFilterEnum.UPCOMING);
            trackAction(Actions.AO_SCHEDULES_UPCOMING);
          }}
          className={classNames(classes.toolbarButton, {
            [classes.active]: classesDateFilter === ClassesDateFilterEnum.UPCOMING,
          })}
        >
          Upcoming
        </Button>
        <Button
          onClick={() => {
            setClassesDateFilter(ClassesDateFilterEnum.PAST);
            trackAction(Actions.AO_SCHEDULES_PAST);
          }}
          className={classNames(classes.toolbarButton, {
            [classes.active]: classesDateFilter === ClassesDateFilterEnum.PAST,
          })}
        >
          Past
        </Button>
      </Flex> */}
      <PebbleSelect
        mt="md"
        size="md"
        title="Schedule"
        data={activityClasses
          .map((activityClass) => {
            return {
              value: activityClass.id,
              label: activityClass.name,
            };
          })
          .sort((a, b) => a.label.localeCompare(b.label))}
        onChange={(value: string | null) => {
          setSelectedClass(value);
          trackAction(Actions.AO_SCHEDULES);
        }}
        value={selectedClass}
        placeholder="Select a schedule"
      />
    </>
  );
};

export default ClassFilters;
