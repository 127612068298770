import { gql } from '@apollo/client';

const paymentConnect = gql`
  mutation paymentConnect($input: MarketplaceSupplierConnectStripeInput!) {
    supplierConnectExistingStripeAccount(input: $input) {
      supplier {
        paymentAccount {
          paymentAccountId
          status
        }
      }
    }
  }
`;

export default paymentConnect;
