import { gql } from '@apollo/client';

const GroupedRegisterSessionAttendeesQuery = gql`
  query GroupedRegisterSessionAttendeesQuery(
    $supplierId: UUID!
    $collectionId: UUID!
    $sessionDate: Date!
  ) {
    collectedRegisterSessions(
      supplierId: $supplierId
      collectionId: $collectionId
      sessionDate: $sessionDate
    ) {
      sessionBookings {
        id
        attendance
        checkInTime
        checkOutTime
        checkInSupplierAdmin {
          id
          fullName
        }
        checkOutSupplierAdmin {
          id
          fullName
        }
        isManuallyAddedBy
        movedFrom
        child {
          id
          fullName
          birthDate
          isAdult
          guardian {
            fullName
            phoneNumber
            email
          }
          guest {
            id
            fullName
            phoneNumber
            email
          }
        }
        status
        bookingTicket {
          id
          ticketNameAtBooking
          booking {
            supplierMarketingOptIn
            additionalInformationEmailStatus
            additionalInformationEmailStatusUpdatedAt
            id
            supplierMarketingOptIn
            activity {
              bookingType
              subscriptionTrialType
              subscriptionTrialSessionCount
              subscriptionTrialPrice
              ageMonthsStart
              ageMonthsEnd
            }
          }
          sessions {
            sessionStart
            isFreeTrialSession
            isPaidTrialSession
          }
        }
        questionAnswers {
          id
          question {
            id
            title
            shortTitle
            type
            isPerAttendee
            isDefault
          }
          answer
        }
        sessionAddons {
          addonId
          name
          description
          price
          note
        }
        bookingAddons {
          addonId
          name
          description
          price
          note
        }
      }
    }
  }
`;

export default GroupedRegisterSessionAttendeesQuery;
