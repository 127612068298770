/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  trackAction as track,
  initializeAmplitude,
  trackPageView,
} from '../../../../../shared/utils/amplitude';
import { Actions } from './actions';

const trackAction = (action: Actions, data?: any) => {
  const supplierId = new URLSearchParams(window.location.search).get('supplierId');

  const dataObject = {
    ...data,
    ...(supplierId && {
      supplierId,
    }),
  };

  track<Actions>(action, dataObject);
};

export { trackAction, initializeAmplitude, trackPageView, Actions };
