import { gql } from '@apollo/client';

const updateAttendanceCheckInOutTimes = gql`
  mutation updateActivitySessionAttendance(
    $checkInInput: UpdateActivitySessionAttendanceInputType!
    $checkOutInput: UpdateActivitySessionAttendanceInputType!
  ) {
    checkIn: updateActivitySessionAttendance(input: $checkInInput) {
      id
      attendance
      checkInTime
      checkOutTime
      checkInSupplierAdmin {
        id
        fullName
      }
      checkOutSupplierAdmin {
        id
        fullName
      }
    }
    checkOut: updateActivitySessionAttendance(input: $checkOutInput) {
      id
      attendance
      checkInTime
      checkOutTime
      checkInSupplierAdmin {
        id
        fullName
      }
      checkOutSupplierAdmin {
        id
        fullName
      }
    }
  }
`;

export default updateAttendanceCheckInOutTimes;
