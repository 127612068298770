import { InviteAttendeeTabsEnum } from 'enums';
import { EMAIL_REGEX } from 'hooks/useManageActivityForm/constants';
import { Actions, trackAction } from 'utils/amplitude';

export const handleEmailsValidation = (
  emailInputs: string[],
  setEmailErrors: (val: { [index: number]: string } | null) => void,
  setActiveTab: (val: InviteAttendeeTabsEnum) => void,
  nextTab: InviteAttendeeTabsEnum,
) => {
  const newErrors: { [index: number]: string } = {};
  const emailSet = new Set();
  const duplicateIndexes: number[] = [];

  emailInputs.forEach((email, index) => {
    if (email === '') {
      newErrors[index] = 'Please enter an email address';
    } else if (!EMAIL_REGEX.test(email)) {
      newErrors[index] = 'Enter a valid email';
    } else {
      if (emailSet.has(email)) {
        duplicateIndexes.push(index);
      } else {
        emailSet.add(email);
      }
    }
  });

  duplicateIndexes.forEach((index) => {
    newErrors[index] = 'Duplicate email';
  });

  const noErrors = Object.keys(newErrors).length === 0 && duplicateIndexes.length === 0;

  setEmailErrors(noErrors ? null : newErrors);
  if (noErrors) {
    setActiveTab(nextTab);
    trackAction(Actions.ENROL_NEXT);
  }
};
