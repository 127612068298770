import { Card, Flex, Title, Divider, useMantineTheme } from '@mantine/core';
import classes from './Card.module.scss';
import { PrimaryBooking, Settings, UpcomingDatesCalendar } from '@icons';
import { ComponentTypes } from '../../Home';
import { CreditCard, UsersThree } from '@phosphor-icons/react';

interface CardsProps {
  children: React.ReactNode;
  section: ComponentTypes;
}

const headers = {
  setup: 'Get Set-up',
  upcoming: 'Upcoming Classes',
  capacity: 'Class Capacity',
  finance: 'Finance',
  performance: 'Performance',
};

const Cards: React.FC<CardsProps> = ({ children, section }) => {
  const theme = useMantineTheme();

  const getIcon = () => {
    switch (section) {
      case ComponentTypes.SETUP:
        return <PrimaryBooking className={classes.icon} />;
      case ComponentTypes.UPCOMING:
        return <UpcomingDatesCalendar className={classes.icon} />;
      case ComponentTypes.CAPACITY:
        return <UsersThree className={classes.icon} color={theme.colors.blue[8]} />;
      case ComponentTypes.FINANCE:
        return <CreditCard className={classes.icon} />;
      default:
        return (
          <Settings
            className={classes.icon}
            color={theme.colors.blue[2]}
            strokeColour={theme.colors.blue[8]}
          />
        );
    }
  };

  return (
    <Card shadow="sm" className={classes.cardWrapper}>
      <Flex align={'center'}>
        {getIcon()}
        <Title order={3} pl={10}>
          {headers[section]}
        </Title>
      </Flex>
      <Divider my="md" color={theme.colors.blue[8]} />
      {children}
    </Card>
  );
};

export default Cards;
