export const PebbleAllWhite = ({ size = 161, ...props }) => (
  <svg width={size} viewBox="0 0 161 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0)">
      <path
        d="M20.4575 38.8692C20.4575 38.8692 21.1005 37.5058 23.4427 36.7213C25.3327 36.1014 27.3487 35.9686 29.3038 36.3354C30.7186 36.5862 32.8266 36.9013 34.8973 37.637C36.9681 38.3727 37.5276 38.8177 37.5134 39.8235C37.4993 40.8293 36.4755 41.6242 36.1604 41.7528C34.9862 42.3049 33.7084 42.6021 32.4112 42.6248C30.2735 42.6891 25.4491 42.7534 24.6285 42.6917C23.8079 42.6299 21.7989 42.5631 20.9951 41.4994C20.1912 40.4357 20.0639 39.5123 20.4575 38.8692Z"
        fill="white"
      />
      <path
        d="M22.4302 29.4768C22.4302 29.4768 23.8591 28.2228 27.1376 28.1714C30.416 28.1199 34.0083 28.6498 35.2996 30.0273C36.591 31.4048 35.7781 32.5662 35.7781 32.5662C35.7781 32.5662 35.1723 33.6042 33.998 33.8743C32.8238 34.1444 31.4733 34.4402 29.6251 34.4518C27.7768 34.4633 23.8772 34.2511 22.9936 33.4858C22.1099 32.7206 21.5196 32.0916 21.5235 31.235C21.5273 30.3784 22.4302 29.4768 22.4302 29.4768Z"
        fill="white"
      />
      <path
        d="M24.9103 22.3972C25.8409 21.7931 26.9288 21.4764 28.0382 21.4866C29.862 21.5187 30.455 21.8814 30.455 21.8814C30.455 21.8814 31.682 22.6441 32.0125 22.7419C32.3431 22.8396 32.9244 23.0416 33.1289 23.6345C33.3334 24.2274 33.6228 24.8949 33.1649 25.2911C32.707 25.6872 32.5617 25.9007 31.5289 26.176C30.6616 26.3915 29.7657 26.4692 28.8742 26.4062C28.1836 26.374 26.5527 26.2068 25.9855 26.2055C25.4183 26.2043 24.3636 25.5933 24.2324 24.8744C24.1012 24.1554 24.1244 22.7367 24.9103 22.3972Z"
        fill="white"
      />
      <path
        d="M46.8594 22.5055C46.8594 22.2326 46.9678 21.9709 47.1607 21.7779C47.3537 21.585 47.6154 21.4766 47.8883 21.4766H55.5449C60.6227 21.4766 63.9115 24.1016 63.9115 28.5505V28.6084C63.9115 33.7299 60.2472 36.1647 55.2555 36.1647H52.4568V41.6733C52.4568 41.9462 52.3484 42.208 52.1554 42.4009C51.9625 42.5939 51.7008 42.7023 51.4279 42.7023H47.8883C47.6154 42.7023 47.3537 42.5939 47.1607 42.4009C46.9678 42.208 46.8594 41.9462 46.8594 41.6733V22.5055ZM55.141 31.7737C57.1025 31.7737 58.3436 30.7061 58.3436 29.119V28.8052C58.3436 27.074 57.1025 26.436 55.1115 26.436H53.4857C53.2129 26.436 52.9511 26.5445 52.7582 26.7374C52.5652 26.9304 52.4568 27.1921 52.4568 27.465V30.7447C52.4568 31.0176 52.5652 31.2793 52.7582 31.4723C52.9511 31.6653 53.2129 31.7737 53.4857 31.7737H55.141Z"
        fill="white"
      />
      <path
        d="M64.4844 34.7121V34.6542C64.4844 30.0947 67.7448 26.4883 72.3609 26.4883C77.7281 26.4883 80.2065 30.442 80.2065 35.0285V35.1249C80.2065 35.3978 80.0981 35.6595 79.9052 35.8525C79.7122 36.0455 79.4505 36.1539 79.1776 36.1539H69.7358C70.168 37.8568 71.4078 38.7262 73.112 38.7262C74.1885 38.7262 75.058 38.4021 75.9416 37.6973C76.1264 37.5477 76.3573 37.4667 76.5951 37.4681C76.8328 37.4695 77.0627 37.5532 77.2458 37.705L78.775 38.9732C78.8861 39.0651 78.9767 39.1793 79.0408 39.3084C79.105 39.4376 79.1413 39.5788 79.1473 39.7228C79.1534 39.8669 79.1291 40.0106 79.076 40.1447C79.023 40.2788 78.9423 40.4002 78.8393 40.5011C77.3937 41.9249 75.4477 42.8265 72.8458 42.8265C67.9467 42.8188 64.4844 39.5301 64.4844 34.7121ZM74.4831 33.3552C74.5616 33.3551 74.639 33.3371 74.7094 33.3025C74.7799 33.2679 74.8415 33.2177 74.8895 33.1556C74.9376 33.0935 74.9708 33.0213 74.9866 32.9444C75.0024 32.8675 75.0004 32.7881 74.9808 32.7121C74.622 31.3577 73.6663 30.5256 72.3609 30.5256C71.112 30.5256 70.2078 31.3487 69.8014 32.6928C69.7788 32.7695 69.7744 32.8505 69.7886 32.9293C69.8028 33.008 69.8351 33.0824 69.8831 33.1464C69.931 33.2105 69.9932 33.2625 70.0648 33.2983C70.1364 33.334 70.2153 33.3526 70.2953 33.3526L74.4831 33.3552Z"
        fill="white"
      />
      <path
        d="M87.0728 40.5404V41.6735C87.0728 41.9464 86.9644 42.2081 86.7715 42.4011C86.5785 42.594 86.3168 42.7024 86.0439 42.7024H82.6227C82.3498 42.7024 82.0881 42.594 81.8951 42.4011C81.7022 42.2081 81.5938 41.9464 81.5938 41.6735V22.4118C81.5938 22.1389 81.7022 21.8771 81.8951 21.6842C82.0881 21.4912 82.3498 21.3828 82.6227 21.3828H86.0439C86.3168 21.3828 86.5785 21.4912 86.7715 21.6842C86.9644 21.8771 87.0728 22.1389 87.0728 22.4118V28.9134C88.1404 27.6143 89.4664 26.4889 91.8047 26.4889C95.5256 26.4889 98.7281 29.5757 98.7281 34.5674V34.6253C98.7281 39.7031 95.5256 42.7912 91.833 42.7912C89.4703 42.7912 88.0864 41.7236 87.0728 40.5404ZM93.3622 34.6548V34.597C93.3622 32.5198 91.9191 31.0484 90.1597 31.0484C88.3706 31.0484 86.9571 32.5198 86.9571 34.597V34.6548C86.9571 36.732 88.3719 38.2034 90.1597 38.2034C91.923 38.2034 93.3661 36.7603 93.3661 34.6548H93.3622Z"
        fill="white"
      />
      <path
        d="M105.594 40.5404V41.6735C105.594 41.9464 105.485 42.2081 105.292 42.4011C105.099 42.594 104.838 42.7024 104.565 42.7024H101.146C100.873 42.7024 100.612 42.594 100.419 42.4011C100.226 42.2081 100.117 41.9464 100.117 41.6735V22.4118C100.117 22.1389 100.226 21.8771 100.419 21.6842C100.612 21.4912 100.873 21.3828 101.146 21.3828H104.565C104.838 21.3828 105.099 21.4912 105.292 21.6842C105.485 21.8771 105.594 22.1389 105.594 22.4118V28.9134C106.661 27.6143 107.989 26.4889 110.326 26.4889C114.048 26.4889 117.25 29.5757 117.25 34.5674V34.6253C117.25 39.7031 114.048 42.7912 110.354 42.7912C107.994 42.7912 106.609 41.7236 105.594 40.5404ZM111.883 34.6548V34.597C111.883 32.5198 110.441 31.0484 108.681 31.0484C106.891 31.0484 105.478 32.5198 105.478 34.597V34.6548C105.478 36.732 106.893 38.2034 108.681 38.2034C110.446 38.2034 111.888 36.7603 111.888 34.6548H111.883Z"
        fill="white"
      />
      <path
        d="M119.687 21.3828H123.089C123.361 21.3828 123.623 21.4912 123.816 21.6842C124.009 21.8771 124.117 22.1389 124.117 22.4118V41.6735C124.117 41.9464 124.009 42.2081 123.816 42.4011C123.623 42.594 123.361 42.7024 123.089 42.7024H119.693C119.42 42.7024 119.158 42.594 118.965 42.4011C118.772 42.2081 118.664 41.9464 118.664 41.6735V22.4118C118.664 22.14 118.772 21.8792 118.963 21.6864C119.155 21.4937 119.415 21.3845 119.687 21.3828Z"
        fill="white"
      />
      <path
        d="M125.609 34.7121V34.6542C125.609 30.0947 128.871 26.4883 133.487 26.4883C138.853 26.4883 141.333 30.442 141.333 35.0285V35.1249C141.333 35.3978 141.224 35.6595 141.031 35.8525C140.839 36.0455 140.577 36.1539 140.304 36.1539H130.86C131.293 37.8568 132.532 38.7262 134.236 38.7262C135.314 38.7262 136.182 38.4021 137.073 37.6973C137.258 37.548 137.488 37.4672 137.726 37.4686C137.963 37.4701 138.193 37.5536 138.376 37.705L139.906 38.9732C140.018 39.0651 140.108 39.1793 140.172 39.3084C140.236 39.4376 140.273 39.5788 140.279 39.7228C140.285 39.8669 140.261 40.0106 140.208 40.1447C140.154 40.2788 140.074 40.4002 139.971 40.5011C138.525 41.9249 136.578 42.8265 133.976 42.8265C129.072 42.8188 125.609 39.5301 125.609 34.7121ZM135.737 33.3552C135.815 33.3553 135.893 33.3374 135.964 33.3029C136.034 33.2684 136.096 33.2182 136.144 33.1561C136.192 33.094 136.226 33.0217 136.241 32.9447C136.257 32.8678 136.255 32.7882 136.236 32.7121C135.876 31.3577 134.92 30.5256 133.616 30.5256C132.366 30.5256 131.463 31.3487 131.055 32.6928C131.032 32.7696 131.028 32.8507 131.042 32.9296C131.057 33.0084 131.089 33.0829 131.137 33.1469C131.185 33.211 131.248 33.263 131.319 33.2987C131.391 33.3344 131.47 33.3528 131.55 33.3526L135.737 33.3552Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="160.129" height="64" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
