import { gql } from '@apollo/client';

export const termCreateMutation = gql`
  mutation termCreate($input: TermCreateInput) {
    termCreate(input: $input) {
      term {
        id
        isDeleted
        createdAt
        updatedAt
        name
        startDate
        endDate
        offDates
        supplier {
          id
        }
      }
    }
  }
`;

export const deleteTermMutation = gql`
  mutation termDelete($id: UUID!) {
    termDelete(id: $id) {
      deleted
    }
  }
`;
