import { gql } from '@apollo/client';

const RegisterSessionTotalRegisteredQuery = gql`
  query RegisterSessionsQuery($supplierId: UUID!, $sessionId: UUID) {
    registerSessions(supplierId: $supplierId, sessionId: $sessionId) {
      id
      totalRegistered
    }
  }
`;

export default RegisterSessionTotalRegisteredQuery;
