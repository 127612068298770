import { useState, FC, ReactNode } from 'react';
import { Text, Popover, ActionIcon, useMantineTheme, MantineSize } from '@mantine/core';
import { Actions, trackAction } from 'utils/amplitude';
import { Info } from '@phosphor-icons/react';

interface ILabelWithPopoverProps {
  labelText: string | ReactNode;
  popoverContent?: ReactNode | string;
  position?: 'bottom' | 'left' | 'right' | 'top';
  disableIcon?: boolean;
  size?: MantineSize | string;
  className?: string;
  amplitudeEvent?: Actions;
  boldInfoIcon?: boolean;
}

export const LabelWithPopover: FC<ILabelWithPopoverProps> = ({
  disableIcon = false,
  labelText,
  popoverContent,
  position = 'top',
  size = 'sm',
  className = '',
  amplitudeEvent,
  boldInfoIcon = true,
}) => {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  return (
    <div style={{ display: 'inline-flex', alignItems: 'center' }} className={className}>
      <Text
        size={size}
        span={true}
        style={{
          textAlign: 'left',
        }}
        c={disableIcon ? theme.colors.gray[7] : theme.colors.blue[8]}
      >
        {labelText}
      </Text>
      <Popover
        opened={opened}
        onClose={() => setOpened(false)}
        width={260}
        position={position}
        withArrow
        styles={{
          dropdown: { backgroundColor: theme.colors.blue[8], color: '#fff', borderRadius: 4 },
          arrow: { backgroundColor: theme.colors.blue[8] },
        }}
      >
        <Popover.Target>
          <ActionIcon
            variant="subtle"
            onClick={() => {
              if (amplitudeEvent) trackAction(amplitudeEvent);
              setOpened((o) => !o);
            }}
            size="sm"
            aria-label="Show popover"
            style={{
              backgroundColor: 'transparent',
            }}
          >
            <Info
              size={18}
              color={disableIcon ? theme.colors.gray[5] : theme.colors.blue[8]}
              weight={boldInfoIcon ? 'bold' : 'regular'}
            />
          </ActionIcon>
        </Popover.Target>
        <Popover.Dropdown>
          <div style={{ backgroundColor: theme.colors.blue[8], color: '#fff' }}>
            {popoverContent}
          </div>
        </Popover.Dropdown>
      </Popover>
    </div>
  );
};
