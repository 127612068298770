import { PaginationControlProps } from '@mantine/core';
import { useEffect, useState } from 'react';

export const usePagination = (totalItems: number, itemsPerPage: number) => {
  const [activePage, setActivePage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [totalPagesCount, setTotalPagesCount] = useState<number>(0);

  const getItemAriaLabel = (page: PaginationControlProps['name']) => {
    switch (page) {
      case 'dots':
        return 'dots element';
      case 'prev':
        return 'previous page button';
      case 'next':
        return 'next page button';
      case 'first':
        return 'first page button';
      case 'last':
        return 'last page button';
      default:
        return `${page} item`;
    }
  };

  useEffect(() => {
    if (totalItems > 0) {
      const totalPages = totalItems / itemsPerPage;
      setTotalPagesCount(Math.ceil(totalPages));
    }
  }, [totalItems, itemsPerPage]);

  useEffect(() => {
    const getOffset = (activePage - 1) * itemsPerPage;
    setOffset(getOffset);
  }, [activePage, itemsPerPage]);

  return {
    activePage,
    setActivePage,
    itemsPerPage,
    offset,
    totalPagesCount,
    getItemAriaLabel,
  };
};
