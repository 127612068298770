import { gql } from '@apollo/client';

const BlockCapacityQuery = gql`
  query checkBlockCapacity($blockId: UUID!) {
    checkBlockCapacity(blockId: $blockId) {
      id
      futureSessions {
        date
        capacity
        spotsLeft
      }
    }
  }
`;

export default BlockCapacityQuery;
