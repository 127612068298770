import { gql } from '@apollo/client';

const RetryMarketplacePaymentCreate = gql`
  mutation retryMarketplacePaymentCharge($paymentId: UUID!) {
    retryMarketplacePaymentCharge(paymentId: $paymentId) {
      payment {
        id
        stripePaymentStatus
        manualCaptureRetries
      }
    }
  }
`;

export default RetryMarketplacePaymentCreate;
