import { gql } from '@apollo/client';

const GroupedRegisterSessionsQuery = gql`
  query GroupedRegisterSessionsQuery(
    $supplierId: UUID!
    $sessionDate: Date!
    $showBooked: Boolean
    $showUnbooked: Boolean
  ) {
    collectedRegisterSessions(
      supplierId: $supplierId
      sessionDate: $sessionDate
      showUnbooked: $showBooked
      showBooked: $showUnbooked
    ) {
      collections {
        id
        name
        activityCount
        activities {
          name
          isDeleted
          id
          location {
            addressLine1
            addressLine2
            city
            country
            postCode
          }
          isOnline
          activityMembershipsFlat
        }
      }
      sessionDate
      sessionCount
    }
  }
`;

export default GroupedRegisterSessionsQuery;
