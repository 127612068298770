import { FC } from 'react';
import classes from './InviteMessage.module.scss';
import { Button, Text, Textarea, Box, Title, ActionIcon, Stack } from '@mantine/core';
import { InviteAttendeeTabsEnum } from 'enums';
import { DateInput } from '@mantine/dates';
import dayjs from 'dayjs';
import { Actions, trackAction } from 'utils/amplitude';
import { CalendarBlank, CaretLeft, Info } from '@phosphor-icons/react';

interface IInviteMessageProps {
  setActiveTab(val: InviteAttendeeTabsEnum): void;
  prevTab: string;
  setCustomMessage(message: string): void;
  setFollowUpDates(followUpDates: string[]): void;
  followUpDates: string[];
  handlePublish: () => void;
}

const InviteMessage: FC<IInviteMessageProps> = ({
  setActiveTab,
  prevTab,
  setCustomMessage,
  setFollowUpDates,
  followUpDates,
  handlePublish,
}) => {
  return (
    <div className={classes.wrapper}>
      <Button
        className={classes.backButton}
        size="md"
        leftSection={<CaretLeft size={20} weight="bold" />}
        variant="subtle"
        onClick={() => {
          trackAction(Actions.ENROL_BACK);
          if (prevTab === InviteAttendeeTabsEnum.MANUALLY_ADDED_SCREEN)
            return setActiveTab(InviteAttendeeTabsEnum.MANUALLY_ADDED_SCREEN);

          if (prevTab === InviteAttendeeTabsEnum.REVIEW_CUSTOMERS_SCREEN)
            return setActiveTab(InviteAttendeeTabsEnum.REVIEW_CUSTOMERS_SCREEN);
        }}
      >
        Back
      </Button>
      <Title className={classes.title} mb={32}>
        Invite Settings
      </Title>
      <Box className={classes.groupBox}>
        <Text className={classes.groupTitle} mb={24}>
          Add a message to invites (Optional)
        </Text>
        <Text component="p" mb={24}>
          This message will be included in the body of the invite email
        </Text>
        <Textarea
          classNames={{
            input: classes.messageBoxInput,
          }}
          placeholder="Eg. We'd love to see you at the next session which is in just two weeks!"
          variant="filled"
          mb={32}
          onBlur={(event) => {
            setCustomMessage(event.target.value);
            trackAction(Actions.ENROL_MESSAGE);
          }}
        />
      </Box>
      <Box className={classes.groupBox} mt={32}>
        <Text className={classes.groupTitle} mb={24}>
          Schedule follow-up emails for missed initial invites? (Optional)
        </Text>
        <Text component="p" mb={24}>
          Emails will be sent at 08:00
        </Text>
        <Stack className={classes.followUpDates}>
          <DateInput
            label="Follow-up 1"
            placeholder="dd/mm/yyyy"
            rightSection={<CalendarBlank size={20} />}
            valueFormat="DD/MM/YYYY"
            classNames={{
              input: classes.dateInput,
              label: classes.dateInputLabel,
              section: classes.dateInputSection,
            }}
            onChange={(val) => {
              setFollowUpDates([...followUpDates, dayjs(val).format('YYYY-MM-DD')]);
              trackAction(Actions.ENROL_FOLLOWUP_ONE);
            }}
            minDate={dayjs(new Date()).add(1, 'day').toDate()}
          />
          <DateInput
            label="Follow-up 2"
            placeholder="dd/mm/yyyy"
            rightSection={<CalendarBlank size={20} />}
            valueFormat="DD/MM/YYYY"
            classNames={{
              input: classes.dateInput,
              label: classes.dateInputLabel,
              section: classes.dateInputSection,
            }}
            onChange={(val) => {
              setFollowUpDates([...followUpDates, dayjs(val).format('YYYY-MM-DD')]);
              trackAction(Actions.ENROL_FOLLOWUP_TWO);
            }}
            minDate={dayjs(new Date()).add(2, 'day').toDate()}
          />
          <DateInput
            label="Follow-up 3"
            placeholder="dd/mm/yyyy"
            rightSection={<CalendarBlank size={20} />}
            valueFormat="DD/MM/YYYY"
            classNames={{
              input: classes.dateInput,
              label: classes.dateInputLabel,
              section: classes.dateInputSection,
            }}
            onChange={(val) => {
              setFollowUpDates([...followUpDates, dayjs(val).format('YYYY-MM-DD')]);
              trackAction(Actions.ENROL_FOLLOWUP_THIRD);
            }}
            minDate={dayjs(new Date()).add(3, 'day').toDate()}
          />
        </Stack>
        <Box className={classes.infoBox} mt={12}>
          <ActionIcon variant="transparent" mr={12}>
            <Info size={26} />
          </ActionIcon>
          <Text>
            We strongly advise you to spread out your follow-up emails. Sending too many, too
            frequently can put customers off booking.
          </Text>
        </Box>
      </Box>
      <Button
        className={classes.primaryButton}
        onClick={() => {
          setActiveTab(InviteAttendeeTabsEnum.SUCCESS_SCREEN);
          handlePublish();
          trackAction(Actions.ENROL_SEND_INVITES);
        }}
        mt={32}
      >
        Send Invites
      </Button>
    </div>
  );
};

export default InviteMessage;
