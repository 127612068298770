import { gql } from '@apollo/client';

const ActivityDetailsQuery = gql`
  query marketplaceActivity($activityId: UUID!) {
    marketplaceActivity(activityId: $activityId) {
      activity {
        id
        name
        slug
        locationName
        allowBlockBookings
        allowIndividualBookings
        location {
          addressLine1
          addressLine2
          city
        }
        supplier {
          id
        }
      }

      singleSessions {
        date
        products {
          time
          timeGroup
        }
      }

      blockBookings {
        dateRange
        time
      }
    }
  }
`;

export default ActivityDetailsQuery;
