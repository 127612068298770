import { gql } from '@apollo/client';

const FinanceReportFiltersQuery = gql`
  query FinanceReportFiltersQuery(
    $supplierId: UUID!
    $datetimeStart: DateTime
    $datetimeEnd: DateTime
  ) {
    financeReportFiltersAndTotals(
      supplierId: $supplierId
      datetimeStart: $datetimeStart
      datetimeEnd: $datetimeEnd
    ) {
      activities {
        id
        name
      }
      datetimeStart
      datetimeEnd
      totalSucceededLast30Days
      totalPending
      totalPotentialRevenue
      totalClicksToBook
    }
  }
`;

export default FinanceReportFiltersQuery;
