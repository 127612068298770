import { notifications } from '@mantine/notifications';
import { ApolloError } from '@apollo/client';
import { XCircle } from '@phosphor-icons/react';

export const showErrorMessage = (error: ApolloError | unknown) => {
  if (process.env.NODE_ENV !== 'test') console.log(error);
  notifications.show({
    title: 'Sorry, something went wrong.',
    message: 'Please try again.',
    icon: <XCircle weight="fill" color="'#D93E1C'" size={30} />,
    color: 'white',
  });
};
