import { FC } from 'react';
import { Card, Skeleton } from '@mantine/core';

export const LoadingCards: FC = () => {
  return (
    <>
      <Card>
        <Skeleton height={23} mb="sm" width="30%" radius="xs" role="alert" aria-busy />
        <Skeleton height={16} mb="sm" width="40%" radius="xs" />
        <Skeleton height={16} mb="sm" width="40%" radius="xs" />
        <Skeleton height={16} mb="xs" width="40%" radius="xs" />
      </Card>
      <Card>
        <Skeleton height={23} mb="sm" width="30%" radius="xs" role="alert" aria-busy />
        <Skeleton height={16} mb="sm" width="40%" radius="xs" />
        <Skeleton height={16} mb="sm" width="40%" radius="xs" />
        <Skeleton height={16} mb="xs" width="40%" radius="xs" />
      </Card>
      <Card>
        <Skeleton height={23} mb="sm" width="30%" radius="xs" role="alert" aria-busy />
        <Skeleton height={16} mb="sm" width="40%" radius="xs" />
        <Skeleton height={16} mb="sm" width="40%" radius="xs" />
        <Skeleton height={16} mb="xs" width="40%" radius="xs" />
      </Card>
    </>
  );
};
