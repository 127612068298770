import { gql } from '@apollo/client';

const SupplierSessionDatesQuery = gql`
  query SupplierSessionDatesQuery($supplierId: UUID!, $includePast: Boolean) {
    supplierClasses(supplierId: $supplierId, includePast: $includePast) {
      sessionDates
    }
  }
`;

export default SupplierSessionDatesQuery;
