import { CustomersTableContent, SortingRule } from '../CustomerSummary';

const sortAtoZ = (customers: CustomersTableContent[]) => {
  return [...customers].sort((a, b) => a.customerFullName.localeCompare(b.customerFullName));
};
const sortZtoA = (customers: CustomersTableContent[]) => {
  return [...customers].sort((a, b) => -a.customerFullName.localeCompare(b.customerFullName));
};

export const sortCustomers = (
  customers: CustomersTableContent[],
  sortingRule: SortingRule,
): CustomersTableContent[] => {
  switch (sortingRule) {
    case SortingRule.A_TO_Z:
      return sortAtoZ(customers);
    case SortingRule.Z_TO_A:
      return sortZtoA(customers);
  }
};
