export const PebbleWhite = ({ size = 103, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1245 497.6" {...props}>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g id="logo">
          <rect width="1245" height="497.6" fill="none" />
          <path
            fill="#4378ff"
            d="M167.53,303.57s4.35-10.9,21.25-17.55a79.73,79.73,0,0,1,42.65-4.18c10.41,1.71,25.82,3.79,41.11,9.21s19.47,8.82,19.61,16.8-7.13,14.38-9.39,15.52A62.86,62.86,0,0,1,255.61,331c-15.58.92-50.74,2.34-56.75,2s-20.64-.45-26.78-8.73S164.84,308.71,167.53,303.57Z"
          />
          <path
            fill="#fb54a2"
            d="M179.38,229.58s11.11-9.75,36.6-10.15,53.42,3.72,63.46,14.43,3.72,19.74,3.72,19.74-4.71,8.07-13.84,10.17-19.63,4.4-34,4.49-44.69-1.56-51.56-7.51-11.46-10.84-11.43-17.5S179.38,229.58,179.38,229.58Z"
          />
          <path
            fill="#9de551"
            d="M198.68,174.53A43.93,43.93,0,0,1,223,167.45c14.18.25,18.79,3.07,18.79,3.07s9.54,5.93,12.11,6.69,7.09,2.33,8.68,6.94,3.84,9.8.28,12.88-4.69,4.74-12.72,6.88a66.23,66.23,0,0,1-20.64,1.79c-5.37-.25-18.05-1.55-22.46-1.56s-12.61-4.76-13.63-10.35S192.57,177.17,198.68,174.53Z"
          />
          <path
            fill="#fff"
            d="M364.33,175.37a8,8,0,0,1,8-8h59.53c39.48,0,65.05,20.41,65.05,55v.45c0,39.82-28.49,58.75-67.3,58.75H407.85V324.4a8,8,0,0,1-8,8H372.33a8,8,0,0,1-8-8Zm64.39,72.06c15.25,0,24.9-8.3,24.9-20.64v-2.44c0-13.46-9.65-18.42-25.13-18.42H415.85a8,8,0,0,0-8,8v25.5a8,8,0,0,0,8,8Z"
          />
          <path
            fill="#fff"
            d="M497.38,270.28v-.45c0-35.45,25.35-63.49,61.24-63.49,41.73,0,61,30.74,61,66.4v.75a8,8,0,0,1-8,8H538.21c3.36,13.24,13,20,26.25,20,8.37,0,15.13-2.52,22-8a8,8,0,0,1,10.14.06l11.89,9.86a8,8,0,0,1,.5,11.88c-11.24,11.07-26.37,18.08-46.6,18.08C524.3,333.31,497.38,307.74,497.38,270.28Zm77.74-10.55a4,4,0,0,0,3.87-5c-2.79-10.53-10.22-17-20.37-17-9.71,0-16.74,6.4-19.9,16.85a4,4,0,0,0,3.84,5.13Z"
          />
          <path
            fill="#fff"
            d="M669,315.59v8.81a8,8,0,0,1-8,8H634.4a8,8,0,0,1-8-8V174.64a8,8,0,0,1,8-8H661a8,8,0,0,1,8,8v50.55c8.3-10.1,18.61-18.85,36.79-18.85,28.93,0,53.83,24,53.83,62.81v.45c0,39.48-24.9,63.49-53.61,63.49C687.64,333.09,676.88,324.79,669,315.59Zm48.9-45.76v-.45c0-16.15-11.22-27.59-24.9-27.59-13.91,0-24.9,11.44-24.9,27.59v.45c0,16.15,11,27.59,24.9,27.59C706.71,297.42,717.93,286.2,717.93,269.83Z"
          />
          <path
            fill="#fff"
            d="M809,315.59v8.81a8,8,0,0,1-8,8H774.42a8,8,0,0,1-8-8V174.64a8,8,0,0,1,8-8H801a8,8,0,0,1,8,8v50.55c8.3-10.1,18.62-18.85,36.79-18.85,28.94,0,53.84,24,53.84,62.81v.45c0,39.48-24.9,63.49-53.62,63.49C827.66,333.09,816.89,324.79,809,315.59Zm48.9-45.76v-.45c0-16.15-11.21-27.59-24.9-27.59-13.91,0-24.9,11.44-24.9,27.59v.45c0,16.15,11,27.59,24.9,27.59C846.73,297.42,857.94,286.2,857.94,269.83Z"
          />
          <path
            fill="#fff"
            d="M914.55,166.64H941a8,8,0,0,1,8,8V324.4a8,8,0,0,1-8,8h-26.4a8,8,0,0,1-8-8V174.64A8,8,0,0,1,914.55,166.64Z"
          />
          <path
            fill="#fff"
            d="M956.6,270.28v-.45c0-35.45,25.36-63.49,61.25-63.49,41.72,0,61,30.74,61,66.4v.75a8,8,0,0,1-8,8H997.43c3.37,13.24,13,20,26.25,20,8.38,0,15.13-2.52,22.06-8a8,8,0,0,1,10.13.06l11.9,9.86a8,8,0,0,1,.5,11.88c-11.24,11.07-26.38,18.08-46.61,18.08C983.52,333.31,956.6,307.74,956.6,270.28Zm78.74-10.55a4,4,0,0,0,3.88-5c-2.8-10.53-10.23-17-20.37-17-9.72,0-16.74,6.4-19.91,16.85a4,4,0,0,0,3.85,5.13Z"
          />
        </g>
      </g>
    </g>
  </svg>
);
