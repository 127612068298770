import { gql } from '@apollo/client';

const SupplierClassCapacity = gql`
  query SupplierClassCapacity($supplierId: UUID!) {
    supplierClassCapacity(supplierId: $supplierId) {
      noCapacity {
        name
        date
        time
        capacity
        bookings
        percentBooked
        activityId
        activityClass
      }
      soldOut {
        name
        date
        time
        capacity
        bookings
        percentBooked
        activityId
        activityClass
      }
      nearCapacity {
        name
        date
        time
        capacity
        bookings
        percentBooked
        activityId
        activityClass
      }
    }
  }
`;

export default SupplierClassCapacity;
