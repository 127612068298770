import { gql } from '@apollo/client';

const TransferWaitListMember = gql`
  mutation transferWaitListMember(
    $waitlistRequestId: UUID!
    $newActivityId: UUID!
    $manualActivityDate: String!
  ) {
    transferWaitListMember(
      waitlistRequestId: $waitlistRequestId
      newActivityId: $newActivityId
      manualActivityDate: $manualActivityDate
    ) {
      transfered
    }
  }
`;

export default TransferWaitListMember;
