import { gql } from '@apollo/client';

const MembershipDelete = gql`
  mutation supplierMembershipDelete($supplierAdminId: UUID!, $supplierId: UUID!) {
    supplierMembershipDelete(supplierAdminId: $supplierAdminId, supplierId: $supplierId) {
      deleted
    }
  }
`;

export default MembershipDelete;
