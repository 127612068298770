import { gql } from '@apollo/client';

const TermsQuery = gql`
  query TermsQuery($supplierId: UUID!) {
    termsBySupplier(supplierId: $supplierId) {
      id
      isDeleted
      createdAt
      updatedAt
      name
      startDate
      endDate
      offDates
      supplier {
        id
      }
    }
  }
`;

export default TermsQuery;
