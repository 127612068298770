import React, { ReactElement, useEffect } from 'react';
import '@mantine/core/styles.css';
import '@mantine/tiptap/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dropzone/styles.css';
import '../styles.css';
import Head from 'next/head';
import { Auth, Amplify } from 'aws-amplify';
import Script from 'next/script';
import { initializeAmplitude, trackPageView } from 'utils/amplitude';
import { ApolloProvider } from '@apollo/client';
import { GraphQLClient, log } from '@pebble/common';
import { CSSVariablesResolver, MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import PebbleTheme from 'theme';
import { DefaultAppPage, PublicPage, FullScreenPage } from 'layouts';
import { AppPropsWithLayoutType } from 'types';
import * as FullStory from '@fullstory/browser';
import { useRouter } from 'next/router';
import { IntercomProvider } from '@utils';

import { ClonedActivityContextProvider } from 'utils/clonedActivityContext/clonedActivityContext';
import { SupplierAdminContextProvider } from 'contexts/SupplierAdmin.context';

const amplifyConfigObject = {
  region: 'eu-west-1',
  userPoolId: process.env.NEXT_PUBLIC_COGNITO_POOL_ID,
  userPoolWebClientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID,
  ssr: true,
};

Amplify.configure(amplifyConfigObject);
Auth.configure(amplifyConfigObject);

const layoutMap = {
  default: DefaultAppPage,
  public: PublicPage,
  fullscreen: FullScreenPage,
};

const MyApp = ({ Component, pageProps }: AppPropsWithLayoutType): ReactElement => {
  const router = useRouter();
  const Layout = layoutMap[Component.layout] || DefaultAppPage;

  const URLpathname = router.pathname.split('/')[1];

  useEffect(() => {
    initializeAmplitude('supplier_portal');
  }, []);

  useEffect(() => {
    log(
      'NEXT_PUBLIC_REACT_APP_GRAPHQL_ENDPOINT: ',
      process.env.NEXT_PUBLIC_REACT_APP_GRAPHQL_ENDPOINT,
    );
    log('NEXT_PUBLIC_VERCEL_ENV: ', process.env.NEXT_PUBLIC_VERCEL_ENV);
  }, []);

  useEffect(() => {
    const excludedRoutesFromTracking = [URLpathname === 'discount-codes'];

    const onExcludedRoute = excludedRoutesFromTracking.some((element) => element === true);

    if (onExcludedRoute) return;
    trackPageView(router.pathname, { supplierId: router.query.supplierId });
  }, [router, URLpathname]);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_FULLSTORY_ORG !== undefined) {
      FullStory.init({
        devMode: process.env.NEXT_PUBLIC_ENV !== 'production',
        orgId: process.env.NEXT_PUBLIC_FULLSTORY_ORG,
      });
      if (pageProps.supplierId || router.query.supplierId) {
        FullStory.identify(pageProps.supplierId || router.query.supplierId);
      }
    }
  }, [pageProps.supplierId, router.query.supplierId]);

  const resolver: CSSVariablesResolver = (theme) => ({
    // To add css variable name to custom variables from the other theme object
    variables: {
      '--mantine-other-midnight': theme.other.midnight,
      '--mantine-other-pinkHover': theme.other.pinkHover,
      '--mantine-other-blueHover': theme.other.blueHover,
    },
    light: {},
    dark: {},
  });

  return (
    <>
      <Head>
        <title>Pebble Supplier Admin</title>
        <meta
          name="viewport"
          content="minimum-scale=1, maximum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>
      <Script src="https://cmp.osano.com/16CVv2SHSkiNfWc9/af2c3f73-3952-4e43-8231-0349479486f0/osano.js"></Script>
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
      `}
      </Script>
      <ApolloProvider client={GraphQLClient}>
        <MantineProvider theme={PebbleTheme} cssVariablesResolver={resolver}>
          <Notifications position="top-right" />
          <SupplierAdminContextProvider token={pageProps.token}>
            <ClonedActivityContextProvider>
              <IntercomProvider supplierId={pageProps.supplierId || router.query.supplierId}>
                <Layout>
                  <Component {...pageProps} />
                </Layout>
              </IntercomProvider>
            </ClonedActivityContextProvider>
          </SupplierAdminContextProvider>
        </MantineProvider>
      </ApolloProvider>
    </>
  );
};

export default MyApp;
