import { gql } from '@apollo/client';

const CreateUnpaidBooking = gql`
  mutation createUnpaidBooking($input: CreateUnpaidBookingInputType!) {
    createUnpaidBooking(input: $input) {
      created
    }
  }
`;

export default CreateUnpaidBooking;
