import { gql } from '@apollo/client';

const SessionCapacityQuery = gql`
  query checkSessionCapacity($sessionId: UUID!) {
    checkSessionCapacity(sessionId: $sessionId) {
      id
      spotsLeft
    }
  }
`;

export default SessionCapacityQuery;
