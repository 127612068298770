import { ReactNode } from 'react';
import { PebbleLogo } from '@ui';
import { NextPage } from 'next';
import Link from 'next/link';
import classes from './FullScreenPage.module.scss';

interface FullScreenPageProps {
  children: ReactNode;
}

export const FullScreenPage: NextPage<FullScreenPageProps> = ({ children }) => {
  return (
    <div className={classes.container}>
      <div className={classes.dialog}>{children}</div>
      <Link href="/" passHref legacyBehavior>
        <PebbleLogo variant="all-white" className={classes.pebbleLogo} />
      </Link>
    </div>
  );
};
