import { gql } from '@apollo/client';

const SupplierFutureSubscriptions = gql`
  query SupplierFutureSubscriptions($supplierId: UUID!, $activityId: UUID, $searchTerms: [String]) {
    activitiesBySupplier(
      supplierId: $supplierId
      activityId: $activityId
      searchTerms: $searchTerms
      bookingType: SUBSCRIPTION
      dateFilter: UPCOMING
    ) {
      activities {
        id
        name
        location {
          addressLine1
          addressLine2
          city
          postCode
        }
        ageRange
        supplier {
          id
        }
        activityType
        schedules {
          id
          dayOfTheWeek
          spotsLeft
          startTime
          endTime
          activeSubs
          nextSessionDate
        }
        tickets {
          subscription {
            id
            name
            price
            ticketType
            capacity
            availabilityStartDate
            isAvailable
          }
        }
      }
    }
  }
`;

export default SupplierFutureSubscriptions;
