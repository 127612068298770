import { useState, FC } from 'react';
import { Button, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Auth } from '@pebble/common';
import { Actions } from 'utils/amplitude';
import { TrackableFormPasswordInput } from 'components';
import classes from './LoginForm.module.scss';
import { PebbleTextInput } from 'components/common';

type FormValues = {
  email: string;
  password: string;
};

interface ILoginFormProps {
  onLogin(): void;
}

export const LoginForm: FC<ILoginFormProps> = ({ onLogin }) => {
  const [serverError, setServerError] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Please enter a valid email address'),
    },
  });

  const handleSubmit = async (values: FormValues) => {
    if (serverError) {
      setServerError('');
    }
    try {
      setIsSubmitting(true);
      await Auth.login(values.email, values.password);

      onLogin();
    } catch (e) {
      setServerError('This e-mail address or password is not valid. Please try again');
      form.setErrors({
        email: true,
        password: true,
      });
    }
    setIsSubmitting(false);
  };
  return (
    <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
      <PebbleTextInput
        required
        label="Your e-mail"
        placeholder="your@email.com"
        radius="lg"
        size="lg"
        style={{
          margin: '24px 0',
        }}
        defaultInput
        action={Actions.SUPPLIER_LOGIN_EMAIL}
        type="email"
        {...form.getInputProps('email')}
      />
      <TrackableFormPasswordInput
        required
        label="Your password"
        size="lg"
        radius="lg"
        styles={{
          root: {
            margin: '24px 0',
          },
          label: {
            paddingBottom: '8px',
          },
        }}
        action={Actions.SUPPLIER_LOGIN_PASSWORD}
        {...form.getInputProps('password')}
      />
      {serverError && (
        <Text size="sm" c="red" role="alert">
          {serverError}
        </Text>
      )}
      <Button
        type="submit"
        className={classes.pinkButton}
        radius="lg"
        size="lg"
        loading={isSubmitting}
      >
        Login
      </Button>
    </form>
  );
};
