import { gql } from '@apollo/client';

const PromotionRedemptionsForSupplierQuery = gql`
  query promotionRedemptionsForSupplier(
    $supplierId: UUID!
    $searchTerm: String
    $limit: Int
    $offset: Int
  ) {
    promotionRedemptionsForSupplier(
      supplierId: $supplierId
      searchTerm: $searchTerm
      limit: $limit
      offset: $offset
    ) {
      summary {
        revenue
        expense
        profit
        totalRedemptions
        totalRecentRedemptions
      }
      redemptions {
        createdAt
        redeemedOn
        bookingId
        activity {
          name
        }
        attendees {
          id
          fullName
          bookingTicketId
        }
        customerEmail
        customerName
        customerPhone
        promotion {
          code
        }
        discount
        finalAmount
      }
    }
  }
`;

export default PromotionRedemptionsForSupplierQuery;
