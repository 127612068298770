import { Anchor, Card, Divider, Flex, Stack, Text } from '@mantine/core';
import classes from './WeekdaysFilter.module.scss';
import { PebbleCheckbox, PebbleCheckboxGroup } from 'components/common';
import { DynamicFilterOption } from 'interfaces';
import classNames from 'classnames';
import { Fragment } from 'react';
import { Actions, trackAction } from 'utils/amplitude';

interface IWeekdaysFilter {
  setWeekdayFilter?(val: string[]): void;
  selectedWeekdayFilter?: string[];
  weekdayFilters?: DynamicFilterOption[];
}

const WeekdaysFilter: React.FC<IWeekdaysFilter> = ({
  selectedWeekdayFilter,
  weekdayFilters,
  setWeekdayFilter,
}) => {
  const isIndeterminate =
    selectedWeekdayFilter?.length === 0 || selectedWeekdayFilter?.length === weekdayFilters?.length;

  if (!setWeekdayFilter || !selectedWeekdayFilter || weekdayFilters?.length === 0) {
    return;
  }

  return (
    <Card className={classes.filterCard}>
      <Stack>
        <Flex justify={isIndeterminate ? 'start' : 'space-between'}>
          <Text fw={700} size="18px">
            Days
          </Text>
          {!isIndeterminate && (
            <Anchor
              component="button"
              onClick={() => {
                setWeekdayFilter([]);
              }}
              style={{
                fontSize: '16px',
                fontWeight: 700,
                color: 'var(--mantine-color-blue-6)',
                textDecoration: 'underline',
              }}
            >
              Select all
            </Anchor>
          )}
        </Flex>
        <PebbleCheckboxGroup value={selectedWeekdayFilter} onChange={setWeekdayFilter}>
          {weekdayFilters?.map((option: DynamicFilterOption) => {
            return (
              <Fragment key={option.value}>
                <PebbleCheckbox
                  value={option.value || option.label}
                  label={option.label}
                  indeterminate={isIndeterminate}
                  classNames={{
                    root: classes.checkboxRoot,
                    icon: classNames(classes.checkboxIcon, {
                      [classes.checkboxIconChecked]: !isIndeterminate,
                    }),
                  }}
                  onClick={(event) => {
                    if (event.currentTarget.checked) {
                      trackAction(Actions.SUB_DAY_FILTER_MOBILE);
                    }
                  }}
                  fullWidth
                />
                <Divider c="#F1F1F2" />
              </Fragment>
            );
          })}
        </PebbleCheckboxGroup>
      </Stack>
    </Card>
  );
};

export default WeekdaysFilter;
