import { ReactNode, useState } from 'react';
import { AppShell, Box, useMantineTheme } from '@mantine/core';
import { Sidebar, Header } from 'components';
import { NextPage } from 'next';
import { trackAction, Actions } from 'utils/amplitude';
import classes from './DefaultAppPage.module.scss';
import { useMediaQuery } from '@mantine/hooks';
import { useRouter } from 'next/router';
import classNames from 'classnames';

interface DefaultAppPageProps {
  children: ReactNode;
}

const STICKY_ROUTES = ['discount-codes', 'customers', 'customer-profile'];

export const DefaultAppPage: NextPage<DefaultAppPageProps> = ({ children }) => {
  const [opened, setOpened] = useState(false);
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);
  const router = useRouter();
  const isStickyRoute = STICKY_ROUTES.some((route) => router.asPath.includes(route));

  return (
    <AppShell
      navbar={{
        width: { base: 224 },
        breakpoint: 'sm',
        collapsed: { mobile: !opened, desktop: opened },
      }}
      header={{ height: 70, collapsed: !isMobile }}
      withBorder={false}
    >
      <AppShell.Navbar w={{ base: 224 }}>
        <Sidebar
          open={opened}
          onDrawerClose={() => setOpened(!opened)}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onLinkClick={(action: Actions, data?: any) => {
            setOpened(false);
            trackAction(action, data);
          }}
        />
      </AppShell.Navbar>
      <AppShell.Header>
        <Header onBurgerClick={() => setOpened(!opened)} sidebarOpen={opened} />
      </AppShell.Header>
      <AppShell.Main bg={theme.colors.gray[2]}>
        <Box className={classNames(classes.boxStyling, { [classes.overflowUnset]: isStickyRoute })}>
          {children}
        </Box>
      </AppShell.Main>
    </AppShell>
  );
};
