import { ActionIcon, Badge, Flex, Text, TextInput } from '@mantine/core';
import { KeyboardEvent, useEffect, useState } from 'react';
import classes from './ActivitySearchTerms.module.scss';
import { showErrorMessage } from 'utils/showErrorMessage/showErrorMessage';
import { useLazyQuery } from '@apollo/client';
import { SupplierAllPaidActivities } from 'graphql/queries';
import { Actions, trackAction } from 'utils/amplitude';
import { X, Plus } from '@phosphor-icons/react';
import { SearchResultActivity } from 'types';

interface IActivitySearchTermsProps {
  token: string;
  supplierId: string;
  setActivitiesSearchResults(val: SearchResultActivity[] | null): void;
  setLoadingActivities(value: boolean): void;
}

const ActivitySearchTerms: React.FC<IActivitySearchTermsProps> = ({
  token,
  supplierId,
  setActivitiesSearchResults,
  setLoadingActivities,
}: IActivitySearchTermsProps) => {
  const [activitySearchTerms, setActivitySearchTerms] = useState<string[] | null>(null);
  const [searchFilterTerm, setSearchTerm] = useState<string>('');

  const handleAddSearchTerm = () => {
    const trimmedSearchTerm = searchFilterTerm.trim();
    if (activitySearchTerms && activitySearchTerms.length > 0) {
      setActivitySearchTerms([...activitySearchTerms, trimmedSearchTerm]);
    } else {
      setActivitySearchTerms([trimmedSearchTerm]);
    }

    setSearchTerm('');
  };

  const deleteSearchTerm = (searchTermToDelete: string) => {
    if (!activitySearchTerms) return;

    const filteredTerms = activitySearchTerms.filter((term: string) => term !== searchTermToDelete);

    if (filteredTerms.length === 0) {
      setActivitySearchTerms(null);
    } else {
      setActivitySearchTerms(filteredTerms);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (searchFilterTerm.length === 0) return;

    if (event.key === 'Enter') {
      handleAddSearchTerm();
      trackAction(Actions.ENROL_ACTIVITY_SEARCH);
    }
  };

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const [getData] = useLazyQuery(SupplierAllPaidActivities, {
    context: {
      headers: {
        Authorization: `${token}`,
      },
    },
    fetchPolicy: 'cache-and-network',
    onError: (error) => {
      showErrorMessage(error);
    },
    onCompleted: (data) => {
      if (data) {
        setActivitiesSearchResults(data?.supplierAllPaidActivities);
        setLoadingActivities(false);
      }
    },
  });

  useEffect(() => {
    if (supplierId && activitySearchTerms) {
      setLoadingActivities(true);
      getData({
        variables: {
          supplierId,
          searchTerms: activitySearchTerms,
        },
      });
    } else {
      setActivitiesSearchResults(null);
    }
  }, [supplierId, activitySearchTerms, setLoadingActivities, getData, setActivitiesSearchResults]);

  return (
    <>
      <Text size="lg" fw={700}>
        Choose Activities
      </Text>
      <Text size="sm" lh="18px" mb="xl">
        Describe what you are looking for here - click enter to add multiple search terms.
      </Text>
      <Flex mb="sm" align="center">
        <TextInput
          data-cy="activity-search-term-input"
          classNames={{ input: classes.activityTextInput }}
          w="100%"
          placeholder="Activity name, Location"
          value={searchFilterTerm}
          onChange={(e) => setSearchTerm(e.currentTarget.value)}
          onKeyDown={handleKeyDown}
          onBlur={() => trackAction(Actions.ENROL_ACTIVITY)}
        />
        <ActionIcon
          ml="sm"
          variant="filled"
          radius="xl"
          size="lg"
          title="add search term"
          className={classes.search}
          onClick={() => {
            handleAddSearchTerm();
            trackAction(Actions.ENROL_ACTIVITY_SEARCH);
          }}
          disabled={searchFilterTerm.length === 0}
        >
          <Plus size={20} weight="bold" />
        </ActionIcon>
      </Flex>
      <Flex className={classes.labelsWrapper}>
        {activitySearchTerms &&
          activitySearchTerms.map((label: string) => (
            <Badge
              w="fit-content"
              onClick={() => {
                deleteSearchTerm(label);
                trackAction(Actions.ENROL_SEARCH_TERM_REMOVE);
              }}
              key={label}
              m={4}
              className={classes.termBadge}
              rightSection={
                <ActionIcon title="remove search term" size={14} ml={4} variant="subtle">
                  <X weight="bold" className={classes.badgeCross} />
                </ActionIcon>
              }
            >
              {capitalizeFirstLetter(label)}
            </Badge>
          ))}
      </Flex>
    </>
  );
};

export default ActivitySearchTerms;
