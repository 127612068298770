import { Popover, PopoverProps } from '@mantine/core';
import classes from './PebblePopover.module.scss';
import { FC, ReactNode } from 'react';
import { useMediaQuery } from '@mantine/hooks';

interface WidthType {
  mobile: string | number;
  desktop: string | number;
}

interface IPebblePopoverProps {
  position: PopoverProps['position'];
  popoverTarget: ReactNode;
  popoverOpened?: boolean;
  onClose?: PopoverProps['onClose'];
  width?: WidthType;
  children: ReactNode;
  disabled?: boolean;
}

export const PebblePopover: FC<IPebblePopoverProps> = ({
  position,
  popoverTarget,
  popoverOpened,
  onClose,
  width,
  children,
  disabled = false,
}) => {
  const isMobile = useMediaQuery('(max-width: 992px)', true);

  const getWidth = () => {
    if (!width) {
      return 'auto';
    }

    return isMobile ? width.mobile : width.desktop;
  };

  return (
    <>
      <Popover
        disabled={disabled}
        opened={popoverOpened}
        onClose={onClose}
        width={getWidth()}
        position={position}
        withArrow
        shadow="md"
        arrowOffset={25}
        radius={4}
        classNames={{
          dropdown: classes.popoverDropdown,
          arrow: classes.popoverArrow,
        }}
      >
        <Popover.Target>{popoverTarget}</Popover.Target>
        <Popover.Dropdown>{children}</Popover.Dropdown>
      </Popover>
    </>
  );
};
