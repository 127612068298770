import PebbleSelect from 'components/common/PebbleSelect/PebbleSelect';
import { SelectProps } from '@mantine/core';
import { MarketplaceSupplierType } from 'interfaces';
import { useSupplierAdmin } from 'contexts/SupplierAdmin.context';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { Actions, trackAction } from 'utils/amplitude';

interface ISupplierSelect extends Omit<SelectProps, 'data'> {
  userToken: string;
}

export const SupplierSelect: React.FC<ISupplierSelect> = ({ userToken, ...rest }) => {
  const { supplierId, supplierAdmin } = useSupplierAdmin();
  const { push } = useRouter();

  const suppliersOptions = useMemo(() => {
    if (supplierAdmin && supplierAdmin?.suppliers?.length) {
      return supplierAdmin?.suppliers.map((s: MarketplaceSupplierType) => ({
        value: s.id,
        label: s.name,
      }));
    }

    return [];
  }, [supplierAdmin]);

  return (
    <PebbleSelect
      mb="lg"
      data={suppliersOptions}
      onFocus={() => trackAction(Actions.SUPPLIER_SEARCH)}
      onChange={(value) => {
        trackAction(Actions.SUPPLIER_SWITCH);
        push({
          query: { supplierId: value },
        });
      }}
      value={supplierId as string}
      radius="xl"
      {...rest}
    />
  );
};
