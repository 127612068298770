import { gql } from '@apollo/client';

const EnableRegisterCheckinCheckoutMode = gql`
  mutation activityToggleRegisterCheckinCheckoutMode(
    $input: ActivityToggleRegisterCheckinCheckoutModeInput
  ) {
    activityToggleRegisterCheckinCheckoutMode(input: $input) {
      activity {
        id
        enableRegisterCheckinCheckoutMode
      }
    }
  }
`;

export default EnableRegisterCheckinCheckoutMode;
