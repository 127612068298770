import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import updateLocale from 'dayjs/plugin/updateLocale';

import { ActivityType, DynamicFilterOption } from 'interfaces';

// Extend and configure dayjs
dayjs.extend(utc);
dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  weekStart: 1, // OPTIONAL, set the start of a week. If the value is 1, Monday will be the start of week instead of Sunday。
});

export enum DateFilters {
  'All dates' = 'ALL',
  'Upcoming' = 'UPCOMING',
  'Past' = 'PAST',
}

export type RawFilter = {
  name: string;
  id?: string;
  value?: string;
};

export type RawFilters = {
  activities: RawFilter[];
  venues: RawFilter[];
  times: RawFilter[];
  weekdays?: RawFilter[];
};

export interface BookingFilters {
  venues: DynamicFilterOption[];
  activities: DynamicFilterOption[];
  times: DynamicFilterOption[];
  weekdays?: DynamicFilterOption[];
}

export interface MarketplaceActivityBookingSessionType {
  sessionStart: string;
  sessionEnd: string;
  booking: {
    activity: ActivityType;
  };
}

export type BookingQueryVariables = {
  activityId?: string | null;
  venue?: string | null;
  datetimeStart?: string | null;
  datetimeEnd?: string | null;
  selectedTimesFilter?: string[];
};

export const toFilterObject = (data: RawFilter) => {
  return {
    label: data.name,
    value: data.id || data.value || '',
  };
};

export const getBookingFilters = (filters: RawFilters): BookingFilters => {
  return {
    activities: filters.activities.map(toFilterObject),
    venues: filters.venues.map(toFilterObject),
    times: filters.times.map(toFilterObject),
    ...(filters.weekdays && {
      weekdays: filters.weekdays.map(toFilterObject),
    }),
  };
};

export const buildFiltersQueryVariables = (
  dateFilter: DateFilters | null,
  customDateFilter: [Date | null, Date | null],
  activityFilter: string | null,
  venueFilter: string | null,
  selectedTimesFilter: string[],
  freeTextFilter: string,
  weekdayFilter: string[] | null,
): BookingQueryVariables => {
  const todayUTC = dayjs(new Date()).utc();
  const [min, max] = customDateFilter;
  const trimmedFreeTextFilter = freeTextFilter ? freeTextFilter.trim() : '';

  const baseFilters = {
    ...(activityFilter !== 'All activities' && {
      activityId: activityFilter,
    }),
    ...(venueFilter !== 'All locations' && {
      venue: venueFilter,
    }),
    ...(selectedTimesFilter.length && {
      times: selectedTimesFilter,
    }),
    ...(trimmedFreeTextFilter.length > 2 && {
      freeTextSearch: trimmedFreeTextFilter,
    }),
    ...(weekdayFilter &&
      weekdayFilter?.length > 0 && {
        weekdays: weekdayFilter?.map((item) => Number(item)),
      }),
  };

  if (min && max) {
    return {
      datetimeStart: dayjs.utc(min).toISOString(),
      datetimeEnd: dayjs.utc(max).add(1, 'day').toISOString(),
      ...baseFilters,
    };
  }

  if (min) {
    return {
      datetimeStart: dayjs.utc(min).toISOString(),
      datetimeEnd: dayjs.utc(min).add(1, 'day').toISOString(),
      ...baseFilters,
    };
  }

  switch (dateFilter) {
    case DateFilters.Past:
      return {
        datetimeEnd: todayUTC.startOf('day').toISOString(),
        ...baseFilters,
      };
    case DateFilters.Upcoming:
      return {
        datetimeStart: todayUTC.startOf('day').toISOString(),
        ...baseFilters,
      };

    case DateFilters['All dates']:
    default:
      return {
        ...baseFilters,
      };
  }
};

export const getEnumKeyByEnumValue = <T extends { [index: string]: string }>(
  myEnum: T,
  enumValue: string,
): keyof T | null => {
  const keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
  return keys.length > 0 ? keys[0] : null;
};

export const getLabelFromSelectedFilter = (
  value: string,
  options: DynamicFilterOption[],
): string => {
  return options.find((option) => option.value === value)?.label || '';
};

export const getSingleFilterLabel = (
  filterValue: string,
  bookingFilters: DynamicFilterOption[] | undefined,
): string => {
  const filter = bookingFilters?.find(
    (option: DynamicFilterOption) => option.value === filterValue,
  );
  return filter?.label || '1 filter';
};

export const formatWeekdayLabels = (
  weekdayFilter: string[],
  bookingFilterWeekdays: DynamicFilterOption[] | undefined,
): string => {
  const labels = bookingFilterWeekdays
    ?.filter((day) => weekdayFilter.includes(day.value))
    .map((day) => day.label.slice(0, 3))
    .join(', ');

  return labels || '';
};

export const formatTimeLabels = (
  selectedTimesFilter: string[],
  timeBookingFilters: DynamicFilterOption[] | undefined,
): string => {
  const times = timeBookingFilters?.filter((time) => selectedTimesFilter.includes(time.value));
  if (!times || times.length === 0) return '';

  if (times.length > 3) {
    const extraCount = times.length - 2;
    const displayedTimes = times
      .slice(0, 2)
      .map((t) => t.label)
      .join(', ');
    return `${displayedTimes} + ${extraCount} more`;
  }

  return times.map((t) => t.label).join(', ');
};
