import { Card, Stack } from '@mantine/core';
import classes from './VenuesFilter.module.scss';
import { PebbleRadio, PebbleRadioGroup } from 'components/common';
import { DynamicFilterOption } from 'interfaces';
import { BookingsViewEnum } from 'enums';
import { getMobileFilterTrackEvent } from '../FiltersDrawer';
import { Actions } from 'utils/amplitude';

interface IVenuesFilter {
  venueFilters: DynamicFilterOption[];
  selectedVenue: string;
  onVenueChange(val: string): void;
  bookingsView?: BookingsViewEnum;
}

const VenuesFilter: React.FC<IVenuesFilter> = ({
  venueFilters,
  selectedVenue,
  onVenueChange,
  bookingsView,
}) => {
  return (
    <Card pb={0} className={classes.filterCard}>
      <Stack>
        <PebbleRadioGroup
          name="categories filter option"
          label="Venues"
          onChange={(val) => {
            onVenueChange(val);
            getMobileFilterTrackEvent(
              Actions.SUB_LOCATION_FILTER_MOBILE,
              Actions.BOOKING_LOCATION_FILTER_MOBILE,
              Actions.SCHEDULED_LOCATION_FILTER_MOBILE,
              bookingsView,
            );
          }}
          apply_filter_styling={'true'}
          value={selectedVenue}
        >
          {venueFilters.map((venue) => (
            <PebbleRadio
              labelPosition="left"
              width={'100%'}
              key={venue.value}
              value={venue.value}
              label={venue.label}
              apply_filter_styling={'true'}
            />
          ))}
        </PebbleRadioGroup>
      </Stack>
    </Card>
  );
};

export default VenuesFilter;
