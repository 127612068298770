import { ActionIcon, Flex, Group, Text, Title, Stack } from '@mantine/core';
import { Dispatch, FC, SetStateAction } from 'react';
import classes from './ActivityCard.module.scss';
import { PebbleCheckbox } from 'components/common';
import classNames from 'classnames';
import { LocationType } from 'interfaces';
import {
  buildFullAddressFromLocation,
  getDates,
} from 'components/pages/ScheduledActivities/ScheduledActivities.utils';
import { Actions, trackAction } from 'utils/amplitude';
import { Baby, CalendarBlank, MapPin } from '@phosphor-icons/react';

interface ActivityCardProps {
  id: string;
  name: string;
  setSelectedActivitiesId: Dispatch<SetStateAction<string[]>>;
  selected: boolean;
  isOnline: boolean;
  minDate: string;
  maxDate: string;
  ageRange: string;
  location?: LocationType;
}

const ActivityCard: FC<ActivityCardProps> = ({
  name,
  id,
  setSelectedActivitiesId,
  selected,
  minDate,
  maxDate,
  ageRange,
  location,
  isOnline,
}) => {
  const activityDateRange = getDates(minDate, maxDate);
  const fullAddress = buildFullAddressFromLocation(
    ['addressLine1', 'addressLine2', 'city', 'postCode'],
    location,
  );
  const activityLocation = isOnline ? 'Online' : fullAddress;

  const getList = (activities: string[]) => {
    if (activities.includes(id)) {
      return activities.filter((activityId) => activityId !== id);
    }

    return [...activities, id];
  };

  return (
    <Flex
      className={classNames(classes.wrapper, { [classes.selected]: selected })}
      mb={11}
      data-testid={'activity-card'}
    >
      <Stack mr={8} gap={6} w={'100%'}>
        <Title order={3} className={classes.activityTitle} w={'100%'}>
          {name}
        </Title>
        <Flex gap={8} className={classes.activityInformationGroup}>
          <Group align="center" wrap="nowrap" gap={4}>
            <ActionIcon size="sm" title="dates" variant="transparent">
              <CalendarBlank size={22} />
            </ActionIcon>
            <Text className={classes.activityInformation}>{activityDateRange}</Text>
          </Group>
          <Group align="center" wrap="nowrap" gap={4}>
            <ActionIcon title={'ages'} variant="transparent">
              <Baby size={20} />
            </ActionIcon>
            <Text className={classes.activityInformation}>{ageRange}</Text>
          </Group>
        </Flex>
        <Group align="center" wrap="nowrap" gap={4}>
          <ActionIcon size={'sm'} title={'location'} variant="transparent">
            <MapPin size={22} />
          </ActionIcon>
          <Text className={classes.activityLocation}>{activityLocation}</Text>
        </Group>
      </Stack>
      <Group justify="center">
        <PebbleCheckbox
          checked={selected}
          onChange={() => {
            setSelectedActivitiesId((prevState) => getList(prevState));
            trackAction(Actions.ENROL_ACTIVITY_SELECT);
          }}
          data-testid="checkbox"
        />
      </Group>
    </Flex>
  );
};

export default ActivityCard;
