import { Flex, Group, Text } from '@mantine/core';
import { DaysOfWeekEnum } from 'enums';
import classes from './DaysOfWeekDisplayLine.module.scss';
import classNames from 'classnames';

interface IDaysOfWeekDisplayLineProps {
  weekdays: DaysOfWeekEnum[];
}

const DaysOfWeekDisplayLine: React.FC<IDaysOfWeekDisplayLineProps> = ({
  weekdays,
}: IDaysOfWeekDisplayLineProps) => {
  const getWeekdayBtnLabel = (item: DaysOfWeekEnum): string => {
    return item.charAt(0).toUpperCase() + item.slice(1).toLocaleLowerCase().substring(0, 1);
  };

  const isWeekdaySelected = (item: DaysOfWeekEnum) => {
    return weekdays.includes(item);
  };

  return (
    <Group gap={0} w={'100%'} mt={10} ml={'-2px'}>
      {Object.values(DaysOfWeekEnum)
        .slice(0, 7)
        .map((item, index) => {
          return (
            <Flex direction="column" gap={0} align="flex-start" w={24} h={24} key={index}>
              <Text
                className={classNames(classes.daysOfWeek, {
                  [classes.dayOfWeekSelected]: isWeekdaySelected(item),
                })}
              >
                {getWeekdayBtnLabel(item)}
              </Text>
              <div className={classNames({ [classes.limeBg]: isWeekdaySelected(item) })}></div>
            </Flex>
          );
        })}
    </Group>
  );
};

export default DaysOfWeekDisplayLine;
