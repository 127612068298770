import { gql } from '@apollo/client';

const GetIframeGeneratorActivities = gql`
  query activitiesBySupplier(
    $supplierId: UUID!
    $dateFilter: TermDateFilterEnum
    $offset: Int
    $limit: Int
  ) {
    activitiesBySupplier(
      supplierId: $supplierId
      dateFilter: $dateFilter
      offset: $offset
      limit: $limit
    ) {
      total
      activities {
        id
        slug
        name
        categories {
          id
          name
          slug
        }
      }
    }
  }
`;
export default GetIframeGeneratorActivities;
