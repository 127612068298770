import { Checkbox, CheckboxGroupProps, CheckboxProps } from '@mantine/core';
import classes from './PebbleCheckbox.module.scss';
import classNames from 'classnames';

type IPebbleCheckboxGroupProps = CheckboxGroupProps;
interface IPebbleCheckboxProps extends CheckboxProps {
  isWeekdayTimeslot?: boolean;
  fullWidth?: boolean;
  checkboxLabelOriginal?: boolean;
  disabledOpaque?: boolean;
}

export const PebbleCheckboxGroup: React.FC<IPebbleCheckboxGroupProps> = (props) => {
  return <Checkbox.Group {...props}>{props.children}</Checkbox.Group>;
};

export const PebbleCheckbox: React.FC<IPebbleCheckboxProps> = ({
  isWeekdayTimeslot,
  fullWidth,
  checkboxLabelOriginal,
  disabledOpaque,
  ...props
}) => {
  return (
    <Checkbox
      {...props}
      labelPosition={props.labelPosition || 'left'}
      indeterminate={props.indeterminate || false}
      classNames={{
        input: classNames(classes.checkboxInput, {
          [classes.checkboxInputIndeterminate]: props.indeterminate,
          //styles for selected WeekdayTimeslot component
          [classes.selectedCheckboxInput]: isWeekdayTimeslot && props.indeterminate,
          [classes.disabledInput]: props.disabled && !disabledOpaque,
        }),
        icon: classNames(classes.checkboxIcon, {
          [classes.checkboxIconIndeterminate]: props.indeterminate,
          [classes.disabledIcon]: props.disabled && !disabledOpaque,
        }),
        label: classNames(classes.checkboxLabel, {
          [classes.disabledLabel]: props.disabled,
          [classes.checkboxLabelOriginal]: checkboxLabelOriginal,
        }),
        body: classNames({
          [classes.fullWidth]: fullWidth,
        }),
        ...props.classNames,
      }}
    />
  );
};
