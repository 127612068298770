import { Term, TermCreateInput } from 'types';
import { GraphQLClient } from '@pebble/common';
import { termCreateMutation, deleteTermMutation } from '../graphql/mutation';

export const addTerm = async (term: TermCreateInput, token: string): Promise<Term> => {
  const { data } = await GraphQLClient.mutate({
    mutation: termCreateMutation,
    variables: {
      input: term,
    },
    context: {
      headers: {
        Authorization: `${token}`,
      },
    },
    fetchPolicy: 'network-only',
  });

  return data?.termCreate?.term;
};

export const deleteTerm = async (termId: string, token: string): Promise<Term> => {
  const { data } = await GraphQLClient.mutate({
    mutation: deleteTermMutation,
    variables: {
      id: termId,
    },
    context: {
      headers: {
        Authorization: `${token}`,
      },
    },
    fetchPolicy: 'network-only',
  });

  return data;
};
