import { FC, useEffect, useRef, useState } from 'react';
import {
  Button,
  AppShell,
  Drawer,
  Box,
  Loader,
  Center,
  Divider,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { PebbleWhite } from '@logos';
import { PrimaryBooking, IconRegister } from '@icons';
import { SidebarLink } from './SidebarLink';
import classes from './Sidebar.module.scss';
import { Actions, trackAction } from 'utils/amplitude';
import { useSupplierAdmin } from 'contexts/SupplierAdmin.context';
import { Auth } from '@pebble/common';
import { trackIntercomEvent, showNewIntercomMessage, IntercomEvents } from '@utils';
import { SupplierSelect } from 'components/SupplierSelect/SupplierSelect';
import Link from 'next/link';
import classNames from 'classnames';
import {
  CalendarBlank,
  Gauge,
  ListBullets,
  PlugsConnected,
  Gear,
  Tag,
  UserList,
} from '@phosphor-icons/react';
import Script from 'next/script';
interface ISidebarProps {
  open: boolean;
  onDrawerClose(): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onLinkClick(action: Actions, data?: any): void;
}

export const Sidebar: FC<ISidebarProps> = ({ open, onDrawerClose, onLinkClick }) => {
  const useDrawer = useMediaQuery('(max-width: 768px)', false);
  const theme = useMantineTheme();
  const userToken = Auth.useGetJWT();
  const { supplierId, loading } = useSupplierAdmin();
  const sidebarContainerRef = useRef<null | HTMLDivElement>(null);
  const [hideShadow, setHideShadow] = useState(true);

  const { hasRegistersAccess } = useSupplierAdmin();

  const calculateBottomScroll = () => {
    if (sidebarContainerRef.current) {
      const { scrollHeight, scrollTop, clientHeight } = sidebarContainerRef.current;
      setHideShadow(scrollHeight - Math.ceil(scrollTop) <= Math.floor(clientHeight));
    } else {
      setHideShadow(true);
    }
  };

  useEffect(() => {
    calculateBottomScroll();
  }, []);

  const SidebarContent = (): JSX.Element => (
    <>
      <PebbleWhite className={classes.pebbleLogo} variant="white" height={72} />
      <Box
        px="xs"
        pb="xs"
        pt="0"
        className={classes.linkContainer}
        ref={sidebarContainerRef}
        onScroll={calculateBottomScroll}
      >
        <>
          <SupplierSelect
            mb="0"
            userToken={userToken}
            classNames={{
              input: classes.supplierSelectInput,
              section: classes.supplierSelectRight,
            }}
          />
          <Divider mb="md" color={theme.colors.gray[5]} />
        </>

        {!hasRegistersAccess && (
          <>
            <SidebarLink
              href="/"
              icon={<Gauge size={18} />}
              label="Dashboard"
              onClick={() => onLinkClick(Actions.SUPPLIER_HOME)}
            />
            <SidebarLink
              href="/term-dates"
              icon={<CalendarBlank size={16} />}
              label="Term dates"
              onClick={() => onLinkClick(Actions.SUPPLIER_TERMS)}
            />
            <SidebarLink
              href="/scheduled-activities"
              icon={<ListBullets size={16} weight="bold" />}
              label="Scheduled activities"
              onClick={() => onLinkClick(Actions.SUPPLIER_SCHEDULED)}
            />
            <SidebarLink
              href="/all-bookings"
              icon={<PrimaryBooking size={14} />}
              label="Bookings"
              onClick={() => onLinkClick(Actions.SUPPLIER_BOOKINGS)}
            />
          </>
        )}

        <SidebarLink
          href="/session-registers"
          icon={<IconRegister size={14} />}
          label="Registers"
          onClick={() => onLinkClick(Actions.SUPPLIER_REGISTERS_SELECTED)}
        />

        {!hasRegistersAccess && (
          <>
            <SidebarLink
              href="/customers"
              icon={<UserList size={16} />}
              label="Customers"
              onClick={() => onLinkClick(Actions.CUSTOMERS_SELECTED)}
              isNew
            />
            <SidebarLink
              href="/discount-codes"
              icon={<Tag size={14} weight="bold" />}
              label="Discount Codes"
              onClick={() => {
                onLinkClick(Actions.DISCOUNT_CODES, { supplierId });
              }}
            />
            <SidebarLink
              href="/integrations"
              icon={<PlugsConnected size={16} weight="fill" />}
              label="Integrations"
              onClick={() => onLinkClick(Actions.SUPPLIER_INTEGRATIONS, { supplierId })}
            />
            <SidebarLink
              href="/settings"
              icon={<Gear size={16} />}
              label="Settings"
              onClick={() => onLinkClick(Actions.SUPPLIER_SETTINGS)}
            />
          </>
        )}
      </Box>
      <footer
        className={classNames(classes.helpArea, { [classes.boxShadow]: !hideShadow })}
        role="footer"
      >
        <div className={classes.footerWrapper}>
          <Button
            component="a"
            href="https://intercom.help/bookpebble/en/collections/3763154-for-activity-providers"
            target="_blank"
            className={classes.supportLink}
            variant="outline"
            radius="lg"
            size="sm"
            my="md"
            w="100%"
            onClick={() => {
              trackIntercomEvent(IntercomEvents.HELP_CENTRE);
              trackAction(Actions.SUPPLIER_HELP_CENTRE);
            }}
          >
            Help Centre
          </Button>
          <Button
            w="100%"
            className={classes.supportLink}
            variant="outline"
            radius="lg"
            size="sm"
            onClick={() => {
              showNewIntercomMessage();
              trackIntercomEvent(IntercomEvents.CONTACT_SUPPORT);
              trackAction(Actions.SUPPLIER_CONTACT);
            }}
          >
            Chat To Us
          </Button>

          <div
            style={{
              marginTop: theme.spacing.md,
            }}
          >
            <div data-tf-live="01HKT5KZFR0RXXCGF7FPBAEAZ3"></div>
            <Script src="//embed.typeform.com/next/embed.js"></Script>
          </div>

          <Link href="/logout" className={classes.logoutLink}>
            Logout
          </Link>
        </div>
      </footer>
    </>
  );

  return useDrawer ? (
    <Drawer
      opened={open}
      onClose={onDrawerClose}
      size="sm"
      overlayProps={{
        opacity: 0.7,
        color: theme.colors.gray[8],
      }}
      closeButtonProps={{
        'aria-label': 'Close navigation',
      }}
      classNames={{
        header: classes.sidebarHeader,
        content: classes.drawerSidebar,
        close: classes.drawerSidebarCloseBtn,
      }}
    >
      {loading ? (
        <Center style={{ height: '100vh' }}>
          <Loader />
        </Center>
      ) : (
        SidebarContent()
      )}
    </Drawer>
  ) : (
    <AppShell.Navbar
      w={{ base: 224 }}
      // hiddenBreakpoint="sm"
      hidden={!open}
      className={classes.sidebar}
    >
      {loading ? (
        <Center style={{ height: '100vh' }}>
          <Loader />
        </Center>
      ) : (
        SidebarContent()
      )}
    </AppShell.Navbar>
  );
};
