import { FC } from 'react';
import { Text } from '@mantine/core';

export const OffDatesPopoverContent: FC = () => (
  <>
    <Text size="sm" mb="sm">
      Terms normally have ‘Off days’ such as holiday breaks including half term or inset days.
    </Text>
    <Text size="sm">“Activities can not be selected to run on Off days”</Text>
  </>
);
