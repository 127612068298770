import { PasswordInput, PasswordInputProps } from '@mantine/core';
import { Actions, trackAction } from 'utils/amplitude';

interface ITrackableFormPasswordInput extends PasswordInputProps {
  action: Actions;
}

export const TrackableFormPasswordInput: React.FC<ITrackableFormPasswordInput> = (props) => {
  const { onBlur, action, ...rest } = props;
  return (
    <PasswordInput
      {...rest}
      autoComplete="off"
      onBlur={(e) => {
        onBlur?.(e);
        trackAction(action);
      }}
    />
  );
};
