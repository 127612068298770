export const formatPrice = (priceInPounds: number) => {
  const opts = {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  return priceInPounds.toLocaleString('en-GB', opts);
};
