import { gql } from '@apollo/client';

const updateActivitySessionAttendance = gql`
  mutation updateActivitySessionAttendance($input: UpdateActivitySessionAttendanceInputType!) {
    updateActivitySessionAttendance(input: $input) {
      id
      attendance
      checkInTime
      checkOutTime
      checkInSupplierAdmin {
        id
        fullName
      }
      checkOutSupplierAdmin {
        id
        fullName
      }
    }
  }
`;

export default updateActivitySessionAttendance;
