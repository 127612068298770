import { gql } from '@apollo/client';

const ActivitySessionsOverview = gql`
  query ActivitySessionsOverview($supplierId: UUID!, $offset: Int, $limit: Int, $activityId: UUID) {
    activitiesBySupplier(
      supplierId: $supplierId
      offset: $offset
      limit: $limit
      activityId: $activityId
    ) {
      activities {
        id
        name
        activityType
        blockSubtype
        waitlistRequestCount
        enableRegisterCheckinCheckoutMode
        sessions {
          id
          date
          startTime
          endTime
          capacity
          bookingCount
        }
        blocks {
          id
          coreStartTime
          coreEndTime
          minDate
          maxDate
          weekdays
          timesVary
          totalSpotsFilledRatio
        }
        schedules {
          id
          capacity
          activeSubs
          startTime
          endTime
          dayOfTheWeek
          nextSessionDate
        }
      }
    }
  }
`;
export default ActivitySessionsOverview;
