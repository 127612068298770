import { gql } from '@apollo/client';

const PromotionsForSupplierQuery = gql`
  query promotionsForSupplier(
    $supplierId: UUID!
    $promotionId: UUID
    $enabled: Boolean
    $disabled: Boolean
    $expired: Boolean
  ) {
    promotionsForSupplier(
      supplierId: $supplierId
      promotionId: $promotionId
      enabled: $enabled
      disabled: $disabled
      expired: $expired
    ) {
      hasPromotions
      summary {
        revenue
        expense
        profit
        totalRedemptions
        totalRecentRedemptions
      }

      promotions {
        id
        createdAt
        status
        name
        code
        discountType
        discountAmount
        allowMultiple
        bookingType
        validFrom
        validTo
        maxRedemptions
        pebblePaysForPromotions
        singleSessionOnly
        maxDiscountAmount
        firstBookingOnlyPerSupplier
        minSessionsNumber
        minAttendeesNumber
        ticketsType
        hasBookings
        isExpired
        redemptions
        revenue
        expense
        profit
        activities {
          id
          name
          location {
            addressLine1
            addressLine2
            city
            postCode
          }
          minDate
          maxDate
          isOnline
          ageRange
        }
        categories {
          id
          name
        }
        activities {
          id
        }
        ticketsType
      }
    }
  }
`;

export default PromotionsForSupplierQuery;
