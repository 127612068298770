export const downloadFile = async (url: string, fileName: string) => {
  try {
    const data = await fetch(url).then((res) => res.blob());
    const dataUrl = window.URL.createObjectURL(data);
    const dataLink = document.createElement('a');
    dataLink.setAttribute('href', dataUrl);
    if (fileName) {
      dataLink.setAttribute('download', fileName);
    }
    document.body.appendChild(dataLink);
    dataLink.click();
    dataLink.remove();
  } catch (e) {
    console.error(e);
  }
};
